import React from "react";
import styled from "styled-components/macro";
// import theme from "theme";

const dayjs = require("dayjs");
require("dayjs/locale/en");
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Container = styled.div`
  flex-wrap: nowrap;
  flex-shrink: 0;
`;

const FoamDate = ({ className, foam, hasDot }) => {
  return (
    <Container className={className}>
      {`${hasDot ? "· " : ""}${dayjs(foam.contentUpdatedAt).fromNow()}`}
    </Container>
  );
};

export default FoamDate;
