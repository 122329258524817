import React from "react";
import styled from "styled-components";
import theme from "theme";

const Container = styled.div`
  background-color: ${(props) =>
    props.$backgroundColor ? props.$backgroundColor : "white"};
  pointer-events: none;

  padding: 20px;
  border: 8px solid black;
  border-radius: 56px;

  color: ${theme.colors.black};
  font-size: 36px;
  font-family: "vag-rundschrift-d";
`;

const Bubble = ({ className, children, backgroundColor }) => {
  return (
    <Container className={className} $backgroundColor={backgroundColor}>
      {children}
    </Container>
  );
};

export default Bubble;
