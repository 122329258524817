// import "./index.css";

import * as Sentry from "@sentry/react";

import App from "./App";
import AuthProvider from "./AuthProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import reportWebVitals from "./reportWebVitals";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://3cc1d857b649557d6a666e54f856efab@o4506610337054720.ingest.sentry.io/4506610397741056",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          /^https:\/\/dev2\.sloof\.co\/api/,
          /^https:\/\/backend\.staging\.foamy\.video\/api/,
          /^https:\/\/backend\.foamy\.video\/api/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: process.env.FOAMY_STAGE,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
      // cacheLocation="localstorage"
      // cookieDomain=".sloof.co"
    > */}
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </GoogleOAuthProvider>
    {/* </Auth0Provider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
