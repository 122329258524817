// Adapted from here: https://www.robinwieruch.de/react-router-private-routes/

import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";

import AddFoam from "pages/AddFoam";
import AdminAddImage from "pages/admin/AddImage";
import AdminHome from "pages/admin/Home";
import AdminRoute from "components/router/AdminRoute";
import Download from "pages/Download";
import Foam from "pages/Foam";
import { GlobalStyle } from "theme";
import Home from "pages/Home";
import Login from "pages/Login";
import Logout from "pages/Logout";
import Privacy from "pages/Privacy";
import PrivateAccount from "pages/private/Account";
import PrivateProfile from "pages/private/Profile";
import PrivateRoute from "components/router/PrivateRoute";
import Profile from "pages/Profile";
import React from "react";
import Support from "pages/Support";
import Terms from "pages/Terms";
import { Toaster } from "react-hot-toast";
import Watch from "pages/Watch";
import Welcome from "pages/Welcome";

function App() {
  function ProfileElement() {
    let { username } = useParams();
    return username.startsWith("@") ? <Profile /> : null;
  }

  return (
    <>
      <GlobalStyle />
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="logout" element={<Logout />} />
          <Route path="welcome" element={<Welcome />} />
          <Route path="foams/add" element={<AddFoam />} />
          <Route path="foams/:id/watch" element={<Watch />} />
          <Route path="foams/:id" element={<Foam />} />
          <Route path="download" element={<Download />} />
          <Route
            path="profile"
            element={
              <PrivateRoute>
                <PrivateProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="account"
            element={
              <PrivateRoute>
                <PrivateAccount />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/images/add"
            element={
              <AdminRoute>
                <AdminAddImage />
              </AdminRoute>
            }
          />
          <Route
            path="admin"
            element={
              <AdminRoute>
                <AdminHome />
              </AdminRoute>
            }
          />
          <Route path=":username" element={<ProfileElement />} />
          <Route path="support" element={<Support />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
