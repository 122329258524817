import { createGlobalStyle, css } from "styled-components/macro";

import { normalize } from "styled-normalize";

const theme = {
  colors: {
    white: "#FFFFFF",
    black: "#000",
    beige: "#F9F7F1",
    blue: "#08D2FA",
    yellow: "#FFB822",
    pink: "#F90091",
    green: "#84DF3F",
    lightGray: "#eceae5",
    mediumGray: "#565656",
    darkGray: "#2C2C2C",
    netflixRed: "#E50914",
  },
  spacing: {
    unit: 8,
  },
  media: {
    phone: (...args) => css`
      @media (min-width: 0px) and (max-width: 767px),
        (orientation: landscape) and (min-width: 0px) and (max-width: 914px) {
        ${css(...args)}
      }
    `,
    phoneQuery: window.matchMedia(
      "(min-width: 0px) and (max-width: 767px), (orientation: landscape) and (min-width: 0px) and (max-width: 914px)"
    ),
  },
};

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  html {
    box-sizing: border-box;
    // scrollbar-gutter: stable;
    outline-color: ${theme.colors.blue};
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    height: 100%;
    background-color: ${theme.colors.beige};

    font-family: "vag-rundschrift-d", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  button {
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export default theme;
