import { EditorContent, useEditor } from "@tiptap/react";

import React from "react";
import StarterKit from "@tiptap/starter-kit";
import styled from "styled-components/macro";
import theme from "theme";

const EditorContainer = styled.div`
  a,
  a span {
    text-decoration: underline;
    text-decoration-color: currentColor;
  }

  .ProseMirror > :first-child {
    margin-block-start: 0;
  }

  .ProseMirror > :last-child {
    margin-block-end: 0;
  }

  p {
    margin-block-start: ${theme.spacing.unit * 2}px;
    margin-block-end: ${theme.spacing.unit * 2}px;
  }

  ul > :first-child p {
    margin-block-start: 0;
  }

  ul > :last-child p {
    margin-block-end: 0;
  }

  ${theme.media.phone`
    p {
      margin-block-start: ${theme.spacing.unit * 1}px;
      margin-block-end: ${theme.spacing.unit * 1}px;
    }
  `}
`;

export const isRichTextEmpty = (richText) => {
  return (
    richText === null ||
    richText === "" ||
    richText === undefined ||
    richText === "<p></p>"
  );
};

const RichTextReader = ({ className, value }) => {
  const editor = useEditor({
    editable: false,
    extensions: [StarterKit],
    content: value,
  });

  return (
    <EditorContainer className={className}>
      <EditorContent editor={editor} />
    </EditorContainer>
  );
};

export default RichTextReader;
