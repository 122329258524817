import { useContext, useEffect, useState } from "react";

import AddFoamDownloadButton from "components/buttons/AddFoamDownloadButton";
import AuthContext from "AuthContext";
import Bubble from "components/general/Bubble";
import Button from "components/general/Button";
import FoamsList from "components/general/FoamsList";
import { Helmet } from "react-helmet";
import Layout from "components//general/Layout";
import NetflixButton from "components/buttons/NetflixButton";
import Step from "components/add_foam/Step";
import Wrapper from "components/general/Wrapper";
import axios from "axios";
import imageScreenshotExtension from "images/img_screenshot_extension.jpg";
import imageScreenshotKarate from "images/img_screenshot_karate.jpg";
import imageScreenshotLogin from "images/img_screenshot_login.jpg";
import imageScreenshotStore from "images/img_screenshot_store.jpg";
import styled from "styled-components/macro";
import theme from "theme.js";
import useIsFoamyInstalled from "hooks/useIsFoamyInstalled";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 20}px;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 8}px;
`;

const StepsRow = styled.div`
  display: flex;
  column-gap: ${theme.spacing.unit * 3}px;
  justify-content: space-between;

  ${theme.media.phone`
    flex-direction: column;
    row-gap: ${theme.spacing.unit * 8}px;
  `}
`;

const SectionTitle = styled.div`
  align-self: flex-start;

  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 44.8px */
  letter-spacing: 0.64px;
`;

function AddFoam() {
  const isFoamyInstalled = useIsFoamyInstalled();
  const { user, setShowLogin } = useContext(AuthContext);

  const [foams, setFoams] = useState(null);

  useEffect(() => {
    const getFoams = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/api/foams`,
        { withCredentials: true }
      );

      setFoams(response.data);
    };
    getFoams();
  }, []);

  const handleLoginClick = () => {
    console.log("handling login click");
    setShowLogin(true);
  };

  return (
    <Layout>
      <Helmet>
        <title>Creating your first Foam | Foamy</title>
      </Helmet>
      <ContentContainer>
        <Wrapper>
          <StepsContainer>
            <SectionTitle>Creating Your First Foam</SectionTitle>
            <StepsRow>
              <Step
                imageSrc={imageScreenshotStore}
                stepNumber={1}
                stepDirection={"Download the extension"}
                stepContent={<AddFoamDownloadButton />}
                isStepCompleted={isFoamyInstalled}
                hasFilter={true}
              />
              <Step
                imageSrc={imageScreenshotLogin}
                stepNumber={2}
                stepDirection={"Log in to Foamy"}
                stepContent={
                  user ? (
                    <Bubble>Done! 💪👈</Bubble>
                  ) : (
                    <Button
                      size={"large"}
                      width={286}
                      onClick={handleLoginClick}
                    >
                      Log In Now
                    </Button>
                  )
                }
                isStepCompleted={user}
                hasFilter={true}
              />
            </StepsRow>
            <StepsRow>
              <Step
                imageSrc={imageScreenshotKarate}
                stepNumber={3}
                stepDirection={"Start watching something on Netflix"}
                stepContent={<NetflixButton />}
              />
              <Step
                imageSrc={imageScreenshotExtension}
                stepNumber={4}
                stepDirection={`Click "Add Foam" from the extension`}
              />
            </StepsRow>
          </StepsContainer>
        </Wrapper>
        {foams && <FoamsList foams={foams} />}
      </ContentContainer>
    </Layout>
  );
}

export default AddFoam;
