import { Link } from "react-router-dom";
import React from "react";
import popcorn from "images/popcorn.svg";
import styled from "styled-components/macro";
import theme from "theme";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  background-image: url(${popcorn});
  background-size: contain;
`;

const LinkContainer = styled(Container)`
  // filter: drop-shadow(5px 5px 5px #222);

  // box-shadow: 2px 2px ${(props) => props.$borderColor || theme.colors.black};

  filter: drop-shadow(
    2px 2px ${(props) => props.$borderColor || theme.colors.black}
  );

  &:hover {
    transform: translate(-1px, -1px);
    filter: drop-shadow(
      3px 3px ${(props) => props.$borderColor || theme.colors.black}
    );
  }

  &:active {
    transform: translate(1px, 1px);
    filter: drop-shadow(
      1px 1px ${(props) => props.$borderColor || theme.colors.black}
    );
  }

  transition: 0.1s ease-in-out;
`;

const Popcorn = ({ className, children }) => {
  return <Container className={className}>{children}</Container>;
};

export const LinkPopcorn = ({ className, children, path }) => {
  return (
    <Link to={path}>
      <LinkContainer className={className}>{children}</LinkContainer>
    </Link>
  );
};

export default Popcorn;
