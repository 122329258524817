import { ExternalLinkButton } from "components/general/Button";
import React from "react";
import chromeLogo from "images/chrome_logo.png";
import styled from "styled-components/macro";
import theme from "theme";

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.unit * 2}px;
`;

const ChromeLogo = styled.img`
  width: 36px;
  height: 36px;
`;

const AddFoamDownloadButton = (props) => {
  return (
    <ExternalLinkButton
      {...props}
      url={`https://chrome.google.com/webstore/detail/${process.env.REACT_APP_EXTENSION_ID}`}
      size={"large"}
      backgroundColor={theme.colors.blue}
    >
      <Container>
        <ChromeLogo src={chromeLogo} alt={"Chrome Logo"} />
        Get Foamy for Chrome
      </Container>
    </ExternalLinkButton>
  );
};

export default AddFoamDownloadButton;
