/* global chrome */

import { useEffect, useState } from "react";

const useIsFoamyInstalled = () => {
  const [isFoamyInstalled, setIsFoamyInstalled] = useState(false);

  useEffect(() => {
    const checkFoamyInstallation = () => {
      try {
        chrome.runtime.sendMessage(
          process.env.REACT_APP_EXTENSION_ID,
          { type: "IS_FOAMY_INSTALLED" },
          () => {
            if (chrome.runtime.lastError) {
              // console.log("error: ", chrome.runtime.lastError);
              setIsFoamyInstalled(false);
            } else {
              // console.log("extension is installed");
              setIsFoamyInstalled(true);
            }
          }
        );
      } catch (e) {
        // console.log(e);
        setIsFoamyInstalled(false);
      }
    };

    // Initial check
    checkFoamyInstallation();

    // Set up interval to check every 2 seconds
    const intervalId = setInterval(checkFoamyInstallation, 2000);

    // Cleanup interval on hook unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return isFoamyInstalled;
};

export default useIsFoamyInstalled;
