import React from "react";
import Screenshot from "components/general/Screenshot";
import imageScreenshotAppa from "images/img_screenshot_appa.jpg";
import imageScreenshotJoe from "images/img_screenshot_joe.jpg";
import imageScreenshotZima from "images/img_screenshot_zima.jpg";
import styled from "styled-components/macro";
import theme from "theme";

const Container = styled.div`
  position: relative;
  // height: 1000px;
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.unit * 4}px ${theme.spacing.unit * 8}px
    ${theme.spacing.unit * 4}px 0;
`;

const Screenshot1 = styled(Screenshot)`
  transform: rotate(-5deg);
  // position: absolute;
  // top: 0;
  // left: 0;
`;

const Screenshot2 = styled(Screenshot)`
  transform: rotate(10deg) translateX(${theme.spacing.unit * 10}px);
  // position: absolute;
  // top: 20rem;
  // left: 0;
  margin-top: -${theme.spacing.unit * 16}px;
  // margin-left: 10rem;
  // left: 10rem;
  // transform: translateX(10px);
`;

const Screenshot3 = styled(Screenshot)`
  transform: rotate(3deg);
  // position: absolute;
  // top: 40px;
  // left: 0;
  margin-top: -${theme.spacing.unit * 10}px;
`;

export const ScreenshotPile = ({ className, avatar, path, size }) => {
  return (
    <Container>
      <Screenshot1 src={imageScreenshotZima} />
      <Screenshot2 src={imageScreenshotAppa} />
      <Screenshot3 src={imageScreenshotJoe} />
    </Container>
  );
};

export default ScreenshotPile;
