import Layout from "components//general/Layout";
import PageTitle from "components/general/PageTitle";
import Wrapper from "components/general/Wrapper";
import axios from "axios";
import styled from "styled-components/macro";
import theme from "theme.js";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const Container = styled.div`
  margin-top: ${theme.spacing.unit * 8}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

function Home() {
  const onDrop = useCallback(async (acceptedFiles) => {
    const formData = new FormData();
    // formData.append("file", acceptedFiles[0]);

    acceptedFiles.forEach((file, index) => {
      // Append each file with a unique name (e.g., file0, file1, ...)
      // formData.append(`file${index}`, file);
      formData.append("files", file);
    });

    // const file = acceptedFiles[0];

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE}/api/admin/images`,
        formData,
        {
          withCredentials: true,
        }
      );

      // Handle the response as needed
      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    // // Do something with the files
    // console.log(acceptedFiles);

    // const reader = new FileReader();

    // reader.onabort = () => console.log("file reading was aborted");
    // reader.onerror = () => console.log("file reading has failed");
    // reader.onload = () => {
    //   // Do whatever you want with the file contents
    //   const binaryStr = reader.result;
    //   console.log(binaryStr);

    //   const payload = {
    //     filename: file.name,
    //     dataBase64: reader.result.split(",")[1],
    //   };

    //   console.log(payload);
    // };
    // reader.readAsArrayBuffer(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // maxFiles: 1,
  });

  return (
    <Layout>
      <Wrapper>
        <PageTitle>Add image</PageTitle>
        <Container>
          <div>Add image</div>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>Drag 'n' drop some files here, or click to select files</p>
            )}
          </div>
        </Container>
      </Wrapper>
    </Layout>
  );
}

export default Home;
