import styled from "styled-components/macro";
import theme from "theme.js";

const Container = styled.div`
  font-size: 32px;
  margin-bottom: ${theme.spacing.unit * 2}px;
`;

function PageTitle({ children }) {
  return <Container>{children}</Container>;
}

export default PageTitle;
