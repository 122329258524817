import Button, { LinkButton } from "components/general/Button";

import AuthContext from "AuthContext";
import Avatar from "components/general/Avatar";
import { Helmet } from "react-helmet";
import Layout from "components/general/Layout";
import LoginForm from "components/LoginForm";
import Wrapper from "components/general/Wrapper";
import styled from "styled-components/macro";
import theme from "theme.js";
import toast from "react-hot-toast";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 8}px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 5}px;
`;

const MeContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.unit * 2}px;
`;

const Intro = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const TabsContainer = styled.div`
  display: flex;
  column-gap: ${theme.spacing.unit * 3}px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 4}px;
`;

const Divider = styled.div`
  margin: ${theme.spacing.unit * 4}px 0;

  align-self: stretch;
  height: 1px;
  background: #000;
  opacity: 0.2;
`;

function Account() {
  const { user, setUserContext, logout } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSave = (newUser) => {
    setUserContext(newUser);

    toast("Account updated!", {
      style: {
        border: `3px solid ${theme.colors.black}`,
        // borderRadius: "16px",
        borderRadius: "128px",
        backgroundColor: theme.colors.green,
      },
    });
  };

  const _logout = async () => {
    await logout();
    navigate("/");
  };

  return (
    <Layout>
      <Helmet>
        <title>Your Foamy Account</title>
      </Helmet>
      {/* <Header /> */}
      <ContentContainer>
        <Wrapper>
          <HeaderContainer>
            <MeContainer>
              <Avatar avatar={user.avatar} />
              <Intro>{`Hi, @${user.username}`}</Intro>
            </MeContainer>
            <TabsContainer>
              <LinkButton path={"/profile"} width={144}>
                My Foams
              </LinkButton>
              <LinkButton
                path={"/account"}
                width={144}
                backgroundColor={theme.colors.blue}
              >
                Account
              </LinkButton>
            </TabsContainer>
          </HeaderContainer>
        </Wrapper>
        <Wrapper width={434}>
          <Container>
            <LoginForm onSave={handleSave} />
            <Divider />
            <Button backgroundColor={theme.colors.pink} onClick={_logout}>
              Logout
            </Button>
          </Container>
        </Wrapper>
      </ContentContainer>
    </Layout>
  );
}

export default Account;
