import React, { useEffect, useState } from "react";

import FoamRow from "components/general/FoamRow";
import Wrapper from "components/general/Wrapper";
import styled from "styled-components/macro";
import theme from "theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 6}px;
`;

const FoamsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 2}px;
  width: 100%;
`;

const Title = styled.div`
  align-self: flex-start;

  font-size: 40px;
  font-style: normal;
  line-height: 140%; /* 56px */
  letter-spacing: 0.8px;
`;

const FoamsList = ({ className, foams, title }) => {
  const [sortedFoams, setSortedFoams] = useState(foams);

  useEffect(() => {
    if (foams) {
      let _foams = [...foams];
      _foams.sort(
        (a, b) =>
          new Date(b.contentUpdatedAt).getTime() -
          new Date(a.contentUpdatedAt).getTime()
      );

      setSortedFoams(_foams);
    }
  }, [foams]);

  if (!foams || !foams.length) {
    return null;
  }

  return (
    <Container className={className}>
      <Wrapper>
        <Title>{title || "Foams Created by the Community"}</Title>
      </Wrapper>
      <Wrapper padding={0}>
        <FoamsContainer>
          {sortedFoams &&
            sortedFoams.map((foam) => (
              <FoamRow foam={foam} key={foam.id}></FoamRow>
            ))}
        </FoamsContainer>
      </Wrapper>
    </Container>
  );
};

export default FoamsList;
