/* global chrome */

import { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import HomeDownloadButton from "components/buttons/HomeDownloadButton";
import Layout from "components//general/Layout";
import Wrapper from "components/general/Wrapper";
import axios from "axios";
import styled from "styled-components/macro";
import theme from "theme.js";
import useIsFoamyInstalled from "hooks/useIsFoamyInstalled";
import { useParams } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  row-gap: ${theme.spacing.unit * 4}px;
  margin: ${theme.spacing.unit * 12}px 0;
  align-items: center;
`;

const Title = styled.div`
  font-size: 40px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0.02em;
`;

function Watch() {
  const { id } = useParams();
  const isFoamyInstalled = useIsFoamyInstalled();
  const [foam, setFoam] = useState(null);

  useEffect(() => {
    const getFoam = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/api/foams/${id}`
      );
      console.log(response.data);
      setFoam(response.data);
    };
    getFoam();
  }, [id]);

  useEffect(() => {
    const incrementWatchCount = async () => {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE}/api/foams/${foam.id}/watch`
      );
      console.log(response.data);
    };

    const redirectToNetflix = () => {
      chrome.runtime.sendMessage(
        process.env.REACT_APP_EXTENSION_ID,
        {
          type: "CONNECT_TAB_TO_VIDEO",
          payload: {
            foamId: foam.id,
            netflixId: foam.video.netflixId,
          },
        },
        (response) => {
          console.log(response);

          // Then open the video in netflix
          window.location.href = `https://www.netflix.com/watch/${foam.video.netflixId}`;
        }
      );
    };

    const startWatching = async () => {
      await incrementWatchCount();
      redirectToNetflix();
    };

    if (isFoamyInstalled && foam) {
      startWatching();
    }
  }, [isFoamyInstalled, foam]);

  if (!foam) {
    return null;
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Watch ${foam?.title} on Foamy`}</title>
      </Helmet>
      {/* <Header /> */}
      <Wrapper width={920}>
        <Container>
          {isFoamyInstalled ? (
            <>
              <Title>Connecting to Netflix...</Title>
              {/* <HomeDownloadButton isFancy={true} /> */}
            </>
          ) : (
            <>
              <Title>Install Foamy to Watch</Title>
              <HomeDownloadButton isFancy={true} />
            </>
          )}
        </Container>
      </Wrapper>
      {/* <Footer /> */}
    </Layout>
  );
}

export default Watch;
