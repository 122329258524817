import React, { forwardRef } from "react";
import styled, { css } from "styled-components/macro";

import { Link } from "react-router-dom";
import theme from "theme";

const Container = css`
  position: relative;

  display: inline-flex;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 16px;
  border: 3px solid ${(props) => props.$borderColor || theme.colors.black};
  box-shadow: 2px 2px ${(props) => props.$borderColor || theme.colors.black};

  ${(props) => {
    if (props.$isFullWidth) {
      return css`
        width: 100%;
      `;
    } else if (props.$width) {
      return css`
        width: ${props.$width}px;
      `;
    }
  }};

  ${(props) => {
    switch (props.$size) {
      case "extraSmall":
        return css`
          height: 40px;

          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0.02em;
        `;
      case "small":
        return css`
          height: 50px;

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        `;
      case "large":
        return css`
          height: 72px;

          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 28px */
          letter-spacing: 0.4px;
        `;
      case "xlarge":
        return css`
          height: 88px;

          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 33.6px */
          letter-spacing: 0.48px;
        `;
      default:
        return css`
          height: 56px;

          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        `;
    }
  }}

  background: ${(props) =>
    props.$backgroundColor ? props.$backgroundColor : theme.colors.white};

  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px ${(props) => props.$borderColor || theme.colors.black};
  }

  &:active {
    transform: translate(1px, 1px);
    box-shadow: 1px 1px ${(props) => props.$borderColor || theme.colors.black};
  }

  transition: 0.1s ease-in-out;

  padding: 0 ${theme.spacing.unit * 2}px;

  color: ${(props) => props.$color || theme.colors.black};

  ${(props) => {
    if (props.$isFancy) {
      return css`
        z-index: 1;
        overflow: hidden;

        span {
          position: absolute;
          width: 25%;
          height: 100%;
          background-color: #000;
          transform: translateY(150%);
          border-radius: 50%;
          left: calc((var(--n) - 1) * 25%);
          transition: 0.5s;
          transition-delay: calc((var(--n) - 1) * 0.1s);
          z-index: -1;
        }
      
        span:nth-child(1) {
          --n: 1;
        }
        span:nth-child(2) {
          --n: 2;
        }
        span:nth-child(3) {
          --n: 3;
        }
        span:nth-child(4) {
          --n: 4;
        }
      
        &:hover {
          color: ${theme.colors.blue};
          transform: translate(-1px, -1px);
          box-shadow: 3px 3px ${(props) =>
            props.$borderColor || theme.colors.black};
      
          span {
            transform: translateY(0) scale(2);
          }
        }
        &:active {
          transform: translate(1px, 1px);
          box-shadow: 1px 1px ${(props) =>
            props.$borderColor || theme.colors.black};
        }
      }`;
    }
  }};

  ${(props) =>
    props.$isDisabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `};
`;

const ButtonContainer = styled.button`
  ${Container};
`;

const LinkButtonContainer = styled(Link)`
  ${Container};
`;

const ExternalLinkButtonContainer = styled.a`
  ${Container};
`;

const Button = forwardRef((props, ref) => {
  const {
    children,
    size,
    isFullWidth,
    width,
    color,
    backgroundColor,
    onClick,
    isDisabled,
    ...rest
  } = props;

  return (
    <ButtonContainer
      ref={ref}
      {...rest}
      $size={size}
      $isFullWidth={isFullWidth}
      $width={width}
      $color={color}
      $backgroundColor={backgroundColor}
      onClick={isDisabled ? null : onClick}
      $isDisabled={isDisabled}
    >
      {children}
    </ButtonContainer>
  );
});

export const LinkButton = forwardRef((props, ref) => {
  const {
    children,
    size,
    isFullWidth,
    width,
    color,
    borderColor,
    backgroundColor,
    path,
    isFancy,
    isDisabled,
    ...rest
  } = props;

  return (
    <LinkButtonContainer
      ref={ref}
      {...rest}
      $size={size}
      $isFullWidth={isFullWidth}
      $width={width}
      $color={color}
      $borderColor={borderColor}
      $backgroundColor={backgroundColor}
      to={path}
      onClick={(e) => {
        e.stopPropagation();
      }}
      $isFancy={isFancy}
    >
      {isFancy && (
        <>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </>
      )}
      {children}
    </LinkButtonContainer>
  );
});

export const ExternalLinkButton = forwardRef((props, ref) => {
  const {
    children,
    size,
    isFullWidth,
    width,
    color,
    borderColor,
    backgroundColor,
    isDisabled,
    url,
    shouldOpenInNewTab,
    isFancy,
    ...rest
  } = props;

  return (
    <ExternalLinkButtonContainer
      ref={ref}
      {...rest}
      $size={size}
      $isFullWidth={isFullWidth}
      $width={width}
      $color={color}
      $borderColor={borderColor}
      $backgroundColor={backgroundColor}
      href={url}
      target={shouldOpenInNewTab ? "_blank" : "_self"}
      onClick={(e) => {
        e.stopPropagation();
      }}
      $isFancy={isFancy}
    >
      {isFancy && (
        <>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </>
      )}
      {children}
    </ExternalLinkButtonContainer>
  );
});

export default Button;
