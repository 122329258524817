import Footer from "components/general/Footer";
import Header from "components/general/Header";
import styled from "styled-components/macro";
import theme from "theme.js";

const Container = styled.div`
  // max-width: 1440px;
  // margin: 0 auto;
  // padding: 0 ${theme.spacing.unit * 4}px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const BottomSpacer = styled.div`
  margin: ${theme.spacing.unit * 8}px 0;
`;

function Layout({ children, hideLoginModal = false }) {
  return (
    <Container>
      <Header hideLoginModal={hideLoginModal} />
      {children}
      <BottomSpacer />
      <Footer />
    </Container>
  );
}

export default Layout;
