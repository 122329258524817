import Button from "components/general/Button";
import { FocusOn } from "react-focus-on";
import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components/macro";
import theme from "theme";

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: ${(props) => (props.$isVisible ? "flex" : "none")};
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  background: ${theme.colors.gray4};
  background-color: rgba(36, 36, 36, 0.5);

  ${(props) =>
    props.$hasBackgroundBlur &&
    `backdrop-filter: blur(2px);
     -webkit-backdrop-filter: blur(2px);`}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  row-gap: ${theme.spacing.unit * 1}px;
  box-sizing: border-box;
  width: 440px;
  padding: ${theme.spacing.unit * 4}px;
  background: ${theme.colors.white};
  border-radius: 16px;
  // text-align: center;
  border: 3px solid ${theme.colors.black};
`;

const Title = styled.div`
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 27.5px */
  letter-spacing: 0.44px;
  color: ${theme.colors.gray4};
`;

const Subtitle = styled.div`
  white-space: pre-wrap;

  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 128.889%;
`;

const ButtonContainer = styled.div`
  align-self: flex-end;
  display: flex;
  column-gap: ${theme.spacing.unit * 2}px;
  margin: ${theme.spacing.unit * 2}px 0;
`;

const ConfirmButton = styled(Button)``;

const CancelButton = styled(Button)``;

const ConfirmModal = (props) => {
  const {
    isVisible,
    title,
    subtitle,
    // content,
    cancelButtonLabel,
    onCancel,
    confirmButtonLabel,
    onConfirm,
    isWarning,
    hasBackgroundBlur,
  } = props;

  return (
    <>
      {createPortal(
        <OverlayContainer
          $isVisible={isVisible}
          $hasBackgroundBlur={hasBackgroundBlur}
        >
          <FocusOn
            enabled={isVisible}
            // gapMode={"padding"}
          >
            <Container>
              {title && <Title>{title}</Title>}
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
              {/* {content && content} */}
              <ButtonContainer>
                {cancelButtonLabel && (
                  <CancelButton isGhost isSmall onClick={onCancel}>
                    {cancelButtonLabel}
                  </CancelButton>
                )}
                <ConfirmButton
                  isSmall
                  onClick={onConfirm}
                  backgroundColor={
                    isWarning ? theme.colors.pink : theme.colors.green
                  }
                >
                  {confirmButtonLabel}
                </ConfirmButton>
              </ButtonContainer>
            </Container>
          </FocusOn>
        </OverlayContainer>,
        document.body
      )}
    </>
  );
};

export default ConfirmModal;
