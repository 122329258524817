import styled from "styled-components/macro";
import theme from "theme.js";

const PaddingContainer = styled.div`
  padding: 0 ${theme.spacing.unit * 2}px;

  // ${theme.media.phone`
  //   padding: ${theme.spacing.unit * 1}px;
  // `}
`;

const WidthContainer = styled.div`
  margin-left: auto;
  margin-right: auto;

  width: 100%;
  max-width: ${(props) => props.$width || 1280}px;
  padding: 0
    ${(props) => (props.$padding !== undefined ? props.$padding : 48)}px;

  ${theme.media.phone`
    max-width: 100%;
    padding: 0;
  `}
`;

function Wrapper({ children, width, padding }) {
  return (
    <PaddingContainer>
      <WidthContainer $width={width} $padding={padding}>
        {children}
      </WidthContainer>
    </PaddingContainer>
  );
}

export default Wrapper;
