import { EditorContent, useEditor } from "@tiptap/react";

import Document from "@tiptap/extension-document";
import NoNewLine from "./NoNewLineExtension";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import React from "react";
import Text from "@tiptap/extension-text";
// import StarterKit from "@tiptap/starter-kit";
import styled from "styled-components";
import theme from "theme";

const EditorContainer = styled.div`
  // border: ${(props) => (props.$borderWidth ? props.$borderWidth : 2)}px solid
  //   ${(props) =>
    props.$borderColor ? props.$borderColor : theme.colors.gray1};
  border-radius: 16px;
  padding: 0 ${theme.spacing.unit * 2}px;
  background-color: #eceae5;

  // font-family: "Inter";
  // font-style: normal;
  // font-weight: 400;
  // font-size: 14px;
  // line-height: 17px;

  p {
    margin-block-start: 17.5px;
    margin-block-end: 17.5px;
  }

  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
    opacity: 0.6;
  }

  .ProseMirror:focus {
    outline: none;
  }
`;

const PlainTextInput = ({
  className,
  text,
  onTextChange,
  placeholder,
  borderColor,
  borderRadius,
  borderWidth,
  placeholderColor,
}) => {
  const editor = useEditor(
    {
      extensions: [
        Document,
        Text,
        Paragraph,
        NoNewLine,
        Placeholder.configure({
          placeholder: placeholder || "",
        }),
      ],
      content: text,
      onUpdate: ({ editor }) => {
        const text = editor.getText();

        onTextChange(text);
      },
    },
    [onTextChange]
  );

  return (
    <EditorContainer
      className={className}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        editor.chain().focus().run();
      }}
      $borderColor={borderColor}
      $borderRadius={borderRadius}
      $borderWidth={borderWidth}
      $placeholderColor={placeholderColor}
    >
      <EditorContent editor={editor} />
    </EditorContainer>
  );
};

export default PlainTextInput;
