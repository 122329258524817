import Button from "components/general/Button";
import Layout from "components//general/Layout";
import PageTitle from "components/general/PageTitle";
import Wrapper from "components/general/Wrapper";
import styled from "styled-components/macro";
import theme from "../theme.js";
import useIsFoamyInstalled from "hooks/useIsFoamyInstalled";

const Container = styled.div`
  margin-top: ${theme.spacing.unit * 8}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const InstallState = styled.div`
  font-size: 40px;
  margin: ${theme.spacing.unit * 4}px;
`;

const InstallTip = styled.div`
  text-align: center;
`;

const DownloadButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${theme.spacing.unit * 2}px 0;
`;

function Download() {
  const isFoamyInstalled = useIsFoamyInstalled();

  return (
    <Layout>
      <Wrapper>
        <PageTitle>Download</PageTitle>
        <Container>
          {isFoamyInstalled !== null && (
            <InstallState>{`Extension ${
              isFoamyInstalled ? "IS" : "is NOT"
            } installed`}</InstallState>
          )}
          <InstallTip>
            Download Chrome extension and{" "}
            <a href="https://ui.vision/howto/install-chrome-extension-from-file">
              install manually
            </a>
          </InstallTip>
          <DownloadButtonContainer>
            <Button
              as={"a"}
              href={`${process.env.PUBLIC_URL}/foamy_video_staging_022424_183129.zip`}
              download
            >
              Download foamy_video_staging_022424_183129.zip
            </Button>
          </DownloadButtonContainer>
        </Container>
      </Wrapper>
    </Layout>
  );
}

export default Download;
