import { useContext, useEffect } from "react";

import AuthContext from "AuthContext";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import { LinkAvatar } from "components/general/Avatar";
import { LinkPopcorn } from "components/general/Popcorn";
import LoginModal from "components/LoginModal";
import Logo from "components/general/Logo";
import iconAdd from "images/ic_add.png";
import styled from "styled-components/macro";
import theme from "theme.js";

const Container = styled.div`
  width: 100%;
  padding: ${theme.spacing.unit * 5}px ${theme.spacing.unit * 8}px;
  height: 160px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;

  ${theme.media.phone`
    padding: ${theme.spacing.unit * 2}px;
  `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.unit * 3}px;
`;

const Header = (props) => {
  const { hideLoginModal } = props;
  const {
    user,
    // setUserContext,
    // login,
    // logout
    showLogin,
    setShowLogin,
  } = useContext(AuthContext);
  // const navigate = useNavigate();

  // const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    if (user && user.username) {
      setShowLogin(false);
    }
  }, [user, setShowLogin]);

  let _showLogin = false;
  if (!hideLoginModal) {
    _showLogin = showLogin || (user && !user.username);
  }

  const handleClose = () => {
    setShowLogin(false);
  };

  return (
    <>
      {_showLogin && <LoginModal onClose={handleClose} />}
      <Container>
        <Logo />
        <ButtonsContainer>
          <LinkPopcorn path={"/foams/add"}>
            <Icon size={16} src={iconAdd} />{" "}
          </LinkPopcorn>
          {user ? (
            <LinkAvatar avatar={user.avatar} path={"/profile"} />
          ) : (
            <Button size={"extraSmall"} onClick={() => setShowLogin(true)}>
              Log In
            </Button>
          )}
        </ButtonsContainer>
      </Container>
    </>
  );
};

export default Header;
