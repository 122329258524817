import React, { forwardRef } from "react";

import Icon from "components/general/Icon";
import { LinkButton } from "components/general/Button";
import iconPlay from "images/ic_play.png";
import styled from "styled-components/macro";
import theme from "theme";

const WatchContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.unit * 1}px;
`;

const WatchButton = forwardRef((props, ref) => {
  const { foam } = props;

  return (
    <LinkButton
      size={"small"}
      width={120}
      backgroundColor={theme.colors.blue}
      path={`/foams/${foam.id}/watch`}
      target={"_blank"}
    >
      <WatchContainer>
        Watch
        <Icon size={16} src={iconPlay} alt={"Play Icon"} />
      </WatchContainer>
    </LinkButton>
  );
});

export default WatchButton;
