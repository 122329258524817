import { useEffect, useState } from "react";

import FoamsList from "components/general/FoamsList";
import { Helmet } from "react-helmet";
import Layout from "components/general/Layout";
import PageTitle from "components/general/PageTitle";
import Wrapper from "components/general/Wrapper";
import axios from "axios";
// import styled from "styled-components/macro";
// import theme from "theme.js";
import { useParams } from "react-router-dom";

function Profile() {
  let { username } = useParams();

  const [foams, setFoams] = useState(null);
  const [_username, _setUsername] = useState(username);

  useEffect(() => {
    // Remove the @ sign
    _setUsername(username.slice(1));
  }, [username]);

  useEffect(() => {
    const getFoams = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/api/users/username/${_username}/foams`,
        {
          withCredentials: true,
        }
      );

      setFoams(response.data);
    };
    getFoams();
  }, [_username]);

  return (
    <Layout>
      <Helmet>
        <title>{`@${_username}'s Foamy Profile`}</title>
      </Helmet>
      <Wrapper>
        <PageTitle>@{_username} Foams</PageTitle>
      </Wrapper>
      {foams && <FoamsList foams={foams} />}
    </Layout>
  );
}

export default Profile;
