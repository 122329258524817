import FoamDate from "components/general/FoamDate";
import Icon from "components/general/Icon";
import { Link } from "react-router-dom";
import Popcorn from "components/general/Popcorn";
import RichTextReader from "components/general/RichTextReader";
import UserLink from "components/general/UserLink";
import WatchButton from "components/buttons/WatchButton";
import iconPlayLarge from "images/ic_play_large.png";
import styled from "styled-components/macro";
import theme from "theme";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  display: flex;
  // align-items: center;
  column-gap: ${theme.spacing.unit * 6}px;

  text-decoration: none;

  color: black;

  &:hover {
    // background: #ecebe8;
    background: #fcfbf8;
  }
  cursor: pointer;
  justify-content: space-between;

  border-radius: 24px;
  padding: ${theme.spacing.unit * 6}px;

  ${theme.media.phone`
    flex-direction: column;
    row-gap: ${theme.spacing.unit * 2}px;

    padding: ${theme.spacing.unit * 4}px;
  `}
`;

const LeftContainer = styled.div`
  flex: 1;

  display: flex;
  // align-items: center;
  column-gap: ${theme.spacing.unit * 6}px;

  ${theme.media.phone`
    flex-direction: column;
    row-gap: ${theme.spacing.unit * 2}px;
  `}
`;

const ThumbnailContainer = styled.div`
  flex: 1.2;

  position: relative;
`;

const ThumbnailLink = styled.a`
  display: block;
  position: relative;
`;

const Thumbnail = styled.img`
  display: block;
  overflow: hidden;

  // width: 352px;
  // height: 167px;
  width: 100%;

  border-radius: 14px;
  border: 2px solid #000;

  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;

  max-width: 100%;
  min-width: 0;

  ${theme.media.phone`
    width: 100%;
  `}
`;

const PopcornContainer = styled.div`
  position: absolute;
  right: -8px;
  top: -8px;
`;

const PlayIcon = styled(Icon)`
  display: none;

  position: absolute;
  top: 50%; /* Move the top edge of the child to the middle of the parent */
  left: 50%; /* Move the left edge of the child to the middle of the parent */
  transform: translate(-50%, -50%);

  opacity: 0.8;

  ${ThumbnailLink}:hover & {
    display: block;
  }
`;

const BubbleCount = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.42px;
`;

const DetailsContainer = styled.div`
  flex: 2;

  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 2}px;
`;

const VideoTitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.02em;

  opacity: 0.7;
`;

const Title = styled(RichTextReader)`
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 27.5px */
  letter-spacing: 0.44px;
`;

const UserRowContainer = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`;

const StyledFoamDate = styled(FoamDate)`
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;

  opacity: 0.6;
`;

const Description = styled(RichTextReader)`
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 128.889%;
`;

const VerticalDivider = styled.div`
  width: 3px;
  background: #edeae1;
  min-height: 100%;
  margin: ${theme.spacing.unit * 3}px 0;

  ${theme.media.phone`
    display: none;
  `}
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;

  ${theme.media.phone`
    justify-content: center;
  `}
`;

const FoamRow = ({ className, foam }) => {
  const navigate = useNavigate();

  let videoTitle = foam.video?.title;
  if (foam.video.type === "episode") {
    videoTitle += ` (${foam.video.episodeTitle})`;
  }

  const handleClick = (e) => {
    navigate(`/foams/${foam.id}`);
  };

  return (
    <Container className={className} onClick={handleClick}>
      <LeftContainer>
        <ThumbnailContainer>
          <ThumbnailLink
            href={`/foams/${foam.id}/watch`}
            target={"_blank"}
            rel={"noreferrer"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Thumbnail src={foam.video.thumbnailUrl} />
            <PopcornContainer>
              <Popcorn>
                <BubbleCount>{foam.bubbleCount}</BubbleCount>
              </Popcorn>
            </PopcornContainer>
            <PlayIcon
              src={iconPlayLarge}
              size={96}
              iconColor={theme.colors.white}
            />
          </ThumbnailLink>
        </ThumbnailContainer>
        <DetailsContainer>
          <Link to={`/foams/${foam.id}`} onClick={(e) => e.stopPropagation()}>
            <VideoTitle>{videoTitle}</VideoTitle>
          </Link>
          <Link to={`/foams/${foam.id}`} onClick={(e) => e.stopPropagation()}>
            <Title value={foam.title || "No title yet"} />
          </Link>
          <UserRowContainer>
            <UserLink foam={foam} isSubtle={true} />
            <StyledFoamDate foam={foam} hasDot={true} />
          </UserRowContainer>

          {foam.description && <Description value={foam.description} />}
        </DetailsContainer>
      </LeftContainer>
      <VerticalDivider />
      <RightContainer>
        <WatchButton foam={foam} />
      </RightContainer>
    </Container>
  );
};

export default FoamRow;
