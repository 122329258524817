import { Link } from "react-router-dom";
import React from "react";
import foamyLogo from "images/logo_blue.svg";
import styled from "styled-components/macro";

const Container = styled(Link)`
  display: flex;
  justify-content: center;
  // padding: 200px 0;

  // width: 512px;
  // height: 384px;

  // svg {
  //   width: 100%;
  //   height: 100%;
  // }
`;

const Image = styled.img`
  height: 40px;
  // width: 83.7px;
  // height: 32.243px;
`;

function Logo() {
  return (
    <Container to={"/"}>
      <Image src={foamyLogo} alt={"Logo"} />
    </Container>
  );
}

export default Logo;
