import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components/macro";
import theme from "theme";

const Image = styled.img`
  width: ${(props) => (props.$size ? props.$size : 40)}px;
  height: ${(props) => (props.$size ? props.$size : 40)}px;
  border-radius: 50%;
  border: 2px solid ${theme.colors.black};
  background-color: ${theme.colors.white};
`;

const LinkImage = styled(Image)`
  box-shadow: 2px 2px ${(props) => props.$borderColor || theme.colors.black};

  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px ${(props) => props.$borderColor || theme.colors.black};
  }

  &:active {
    transform: translate(1px, 1px);
    box-shadow: 1px 1px ${(props) => props.$borderColor || theme.colors.black};
  }

  transition: 0.1s ease-in-out;
`;

const Avatar = ({ className, avatar, onClick, size }) => {
  return (
    <Image
      className={className}
      src={avatar?.url}
      alt={avatar?.title}
      onClick={onClick}
      $size={size}
    />
  );
};

export const LinkAvatar = ({ className, avatar, path, size }) => {
  return (
    <Link to={path}>
      <LinkImage
        className={className}
        src={avatar?.url}
        alt={avatar?.title}
        $size={size}
      />
    </Link>
  );
};

export default Avatar;
