import Button from "components/general/Button";
import IconButton from "components/general/IconButton";
import React from "react";
import shareIcon from "images/share_icon.png";
import styled from "styled-components/macro";
import theme from "theme";
import toast from "react-hot-toast";

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.unit * 1}px;
`;

const ShareIcon = styled.img`
  width: 14px;
  height: 12.177px;
`;

const ShareButton = ({ url }) => {
  const handleClick = (e) => {
    e.stopPropagation();

    navigator.clipboard.writeText(url);

    toast("Link copied!", {
      style: {
        border: `3px solid ${theme.colors.black}`,
        borderRadius: "128px",
        backgroundColor: theme.colors.yellow,
      },
    });
  };

  return (
    <Button
      onClick={handleClick}
      size={"small"}
      width={120}
      backgroundColor={theme.colors.yellow}
    >
      <Container>
        Share
        <ShareIcon src={shareIcon} alt={"Share Icon"} />
      </Container>
    </Button>
  );
};

export const IconShareButton = ({ url }) => {
  const handleClick = (e) => {
    e.stopPropagation();

    navigator.clipboard.writeText(url);

    toast("Link copied!", {
      style: {
        border: `3px solid ${theme.colors.black}`,
        borderRadius: "128px",
        backgroundColor: theme.colors.yellow,
      },
    });
  };

  return (
    <IconButton
      icon={shareIcon}
      onClick={handleClick}
      backgroundColor={theme.colors.yellow}
    />
  );
};

export default ShareButton;
