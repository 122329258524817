import React, { useEffect, useState } from "react";

import Avatar from "components/general/Avatar";
import axios from "axios";
import styled from "styled-components/macro";
import theme from "theme";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // column-gap: ${theme.spacing.unit * 1}px;
`;

const AvatarContainer = styled.div`
  border-radius: 12px;
  padding: ${theme.spacing.unit * 1}px;
  background-color: ${(props) =>
    props.$isSelected ? theme.colors.lightGray : "transparent"};
`;

const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`;

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const AvatarSelect = ({ className, selectedAvatar, onAvatarChange }) => {
  const [_selectedAvatar, _setSelectedAvatar] = useState(selectedAvatar);
  const [avatarOptions, setAvatarOptions] = useState(null);

  useEffect(() => {
    const getAvatars = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/api/avatars`,
        { withCredentials: true }
      );
      // console.log(response.data);

      const _avatarOptions = shuffleArray(response.data);

      setAvatarOptions(_avatarOptions);
    };
    getAvatars();
  }, []);

  const handleAvatarClick = (avatar) => (e) => {
    console.log(avatar);

    _setSelectedAvatar(avatar);
    onAvatarChange(avatar);
  };

  if (!avatarOptions) {
    return null;
  }

  console.log(avatarOptions);

  return (
    <Container className={className}>
      {avatarOptions.map((avatar) => {
        return (
          <AvatarContainer
            key={avatar.id}
            $isSelected={avatar.id === _selectedAvatar?.id}
          >
            <StyledAvatar
              avatar={avatar}
              onClick={handleAvatarClick(avatar)}
            ></StyledAvatar>
          </AvatarContainer>
        );
      })}
    </Container>
  );
};

export default AvatarSelect;
