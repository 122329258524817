import React, { useContext } from "react";

import AuthContext from "AuthContext";
import Dialog from "components/general/Dialog";
import { GoogleLogin } from "@react-oauth/google";
import IconButton from "components/general/IconButton";
import Label from "components/general/Label";
import LoginForm from "components/LoginForm";
import axios from "axios";
import iconClose from "images/ic_close.png";
import styled from "styled-components/macro";
import theme from "theme";
import useIsPhone from "hooks/useIsPhone";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 4}px;
`;

const GoogleInputGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 1}px;
`;

const LoginModal = ({ onClose }) => {
  const { user, setUserContext, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const isPhone = useIsPhone();

  const handleSave = (newUser) => {
    setUserContext(newUser);
  };

  const verifyGoogleCredentials = async (credentialResponse) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE}/api/auth/google/verify`,
      credentialResponse,
      { withCredentials: true }
    );
    console.log(response.data); // This should be the user

    // // Store the access token in local storage
    // const authDataString = JSON.stringify(response.data);

    // localStorage.setItem("authData", authDataString);

    const user = response.data;

    setUserContext(user);

    // Redirect to the homepage if they have a username
    // if (user?.username) {
    //   navigate("/");
    // }

    // Close the modal if they have a username
    if (user?.username) {
      onClose();
    }
  };

  const handleCloseClick = async () => {
    // If they aren't logged in at all, just close the dialog
    if (!user) {
      if (onClose) {
        onClose();
      }
    } else if (user && (!user.username || !user.avatar)) {
      // We have a user with incomplete data
      // Log them out and close the dialog
      await logout();

      if (onClose) {
        onClose();
      }

      navigate("/");
    }
  };

  return (
    <Dialog
      isVisible={true}
      hasBackgroundBlur={true}
      onCloseClick={handleCloseClick}
      headerRightItem={
        <IconButton onClick={handleCloseClick} icon={iconClose} />
      }
    >
      <Container>
        {user && (!user.username || !user.avatar) ? (
          <LoginForm onSave={handleSave} />
        ) : (
          <GoogleInputGroupContainer>
            <Label>Log In or Sign Up</Label>
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                console.log(credentialResponse);
                verifyGoogleCredentials(credentialResponse);
              }}
              onError={() => {
                console.log("Login Failed");
              }}
              size={"large"}
              text={"continue_with"}
              width={isPhone ? 200 : 338}
            />
          </GoogleInputGroupContainer>
        )}
      </Container>
    </Dialog>
  );
};

export default LoginModal;
