import { useEffect, useState } from "react";

import FoamsList from "components/general/FoamsList";
import { Helmet } from "react-helmet";
import HomeDownloadButton from "components/buttons/HomeDownloadButton";
import Icon from "components/general/Icon";
import Layout from "components//general/Layout";
import Screenshot from "components/general/Screenshot";
import Wrapper from "components/general/Wrapper";
import axios from "axios";
// import homeHero from "images/home_hero.png";
import iconChatFlair from "images/ic_chat_flair.png";
import iconShareFlair from "images/ic_share_flair.png";
import imageScreenshotAppa from "images/img_screenshot_appa.jpg";
import imageScreenshotZima from "images/img_screenshot_zima.jpg";
import styled from "styled-components/macro";
import theme from "theme.js";

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   row-gap: ${theme.spacing.unit * 4}px;
// `;

// const Section = styled.div`
//   width: 100%;
//   background-color: ${(props) => props.$backgroundColor || theme.colors.beige};
//   display: flex;
//   flex-direction: column;
// `;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 16}px;
  margin-bottom: ${theme.spacing.unit * 24}px;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 8}px;
  align-items: center;
  margin: ${theme.spacing.unit * 4}px auto;
`;

const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 4}px;
`;

const Title = styled.div`
  text-align: center;
  font-size: 40px;
  font-style: normal;
  line-height: 140%; /* 56px */
  letter-spacing: 0.8px;
`;

// const Hero = styled.img`
//   width: 1139px;
//   max-width: 100%;
// `;

const HeroVideoContainer = styled.div`
  max-width: 800px;
  border: 3px solid ${theme.colors.black};
  border-radius: 16px;
  overflow: hidden;
`;

const HeroVideo = styled.video`
  width: 100%;
  display: block;
`;

const Subtitle = styled.div`
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 28px */
  letter-spacing: 0.4px;

  max-width: 544px;
`;

const TwoColumnContainer = styled.div`
  display: flex;
  column-gap: ${theme.spacing.unit * 20}px;
  justify-content: space-between;

  ${theme.media.phone`
    flex-direction: column;
    row-gap: ${theme.spacing.unit * 8}px;
  `}
`;

const CalloutContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 4}px;

  ${theme.media.phone`
    row-gap: ${theme.spacing.unit * 2}px;
  `}
`;

const CalloutImage = styled.div`
  max-width: 100%;
  min-width: 0;
  height: auto;
  display: flex;

  ${theme.media.phone`
    width: 100%;
  `}
`;

const CalloutTitle = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 44.8px */
  letter-spacing: 0.64px;
`;

const CalloutDescription = styled.div`
  // width: 380px;

  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 28px */
  letter-spacing: 0.4px;
`;

function Home() {
  const [foams, setFoams] = useState(null);

  useEffect(() => {
    const getFoams = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/api/foams`,
        { withCredentials: true }
      );

      setFoams(response.data);
    };
    getFoams();
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Foamy - A New Way to Watch Netflix</title>
      </Helmet>
      <Wrapper>
        <ContentContainer>
          <CenteredContainer>
            <TitleAndSubtitleContainer>
              <Title>Netflix Turned Up to 11</Title>
              <Subtitle>
                Watch Netflix in a fun new way, with pop-up comments.
              </Subtitle>
            </TitleAndSubtitleContainer>
            {/* <Hero src={homeHero} alt={"Home Hero"} />
             */}
            <HeroVideoContainer>
              <HeroVideo playsInline autoPlay loop muted>
                <source
                  src={`${process.env.PUBLIC_URL}/teaser_1710629940.mp4`}
                  type="video/mp4"
                />
              </HeroVideo>
            </HeroVideoContainer>
            <HomeDownloadButton isFancy={true} />
          </CenteredContainer>
          <TwoColumnContainer>
            <CalloutContainer>
              <CalloutImage>
                <Screenshot src={imageScreenshotAppa} />
              </CalloutImage>
              <Icon src={iconChatFlair} alt={"Chat Flair"} size={48} />
              <CalloutTitle>Watch and Enjoy</CalloutTitle>
              <CalloutDescription>
                Foams are collections of Bubbles (comments) on a Netflix episode
                or movie. Experience your favorite content, now with added Foam.
              </CalloutDescription>
            </CalloutContainer>
            <CalloutContainer>
              <CalloutImage>
                <Screenshot src={imageScreenshotZima} />
              </CalloutImage>
              <Icon src={iconShareFlair} alt={"Share Flair"} size={48} />
              <CalloutTitle>Create Your Own</CalloutTitle>
              <CalloutDescription>
                Contribute your unique perspective by adding your own Foam.
                Factoids, reactions, inside jokes - anything goes. Then share it
                with friends, or open it up to the world.
              </CalloutDescription>
            </CalloutContainer>
            {/* <ShareCalloutContainer>
            <ShareCalloutImage
              src={imageBubbleExample2}
              alt={"Bubble Example 2"}
            />
            <ShareCalloutInfoContainer>
              <ShareCalloutTitle>
                Add Commentary, Share with Friends
              </ShareCalloutTitle>
              <ShareCalloutDescription>
                Have any inside knowledge around your favorite show? Add your
                thoughts and share them with the world.
              </ShareCalloutDescription>
            </ShareCalloutInfoContainer>
          </ShareCalloutContainer> */}
          </TwoColumnContainer>
        </ContentContainer>
      </Wrapper>
      {foams && <FoamsList foams={foams} />}
    </Layout>
  );
}

export default Home;
