import React from "react";
import Screenshot from "components/general/Screenshot";
import StepNumber from "components/general/StepNumber";
import styled from "styled-components/macro";
import theme from "theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 3}px;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const StyledScreenshot = styled(Screenshot)`
  width: 494px;
  max-width: 100%;
  min-width: 0;
  height: auto;

  ${theme.media.phone`
    width: 100%;
  `}
`;

const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.unit * 3}px;
`;

const Direction = styled.div`
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 30.8px */
  letter-spacing: 0.44px;
`;

const Step = ({
  className,
  imageSrc,
  stepNumber,
  stepDirection,
  stepContent,
  isStepCompleted,
  hasFilter,
}) => {
  return (
    <Container className={className}>
      <ImageContainer>
        <StyledScreenshot src={imageSrc} hasFilter={hasFilter} />
        {stepContent && <ContentContainer>{stepContent}</ContentContainer>}
      </ImageContainer>
      <InfoContainer>
        <StepNumber
          backgroundColor={
            isStepCompleted ? theme.colors.green : theme.colors.white
          }
        >
          {stepNumber}
        </StepNumber>
        <Direction>{stepDirection}</Direction>
      </InfoContainer>
    </Container>
  );
};

export default Step;
