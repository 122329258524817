import React, { forwardRef } from "react";
import styled, { css } from "styled-components/macro";

import Icon from "components/general/Icon";
import theme from "theme";

const Container = css`
  position: relative;

  display: inline-flex;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  border: 3px solid ${(props) => props.$borderColor || theme.colors.black};
  box-shadow: 2px 2px ${(props) => props.$borderColor || theme.colors.black};

  width: 36px;
  height: 36px;

  background: ${(props) =>
    props.$backgroundColor ? props.$backgroundColor : theme.colors.white};

  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px ${(props) => props.$borderColor || theme.colors.black};
  }

  &:active {
    transform: translate(1px, 1px);
    box-shadow: 1px 1px ${(props) => props.$borderColor || theme.colors.black};
  }

  transition: 0.1s ease-in-out;

  padding: 0 ${theme.spacing.unit * 2}px;

  color: ${(props) => props.$color || theme.colors.black};

  ${(props) =>
    props.$isDisabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `};
`;

const ButtonContainer = styled.button`
  ${Container};
`;

const IconButton = forwardRef((props, ref) => {
  const {
    isFullWidth,
    color,
    backgroundColor,
    borderColor,
    onClick,
    isDisabled,
    icon,
    iconColor,
    size,
    ...rest
  } = props;

  return (
    <ButtonContainer
      ref={ref}
      {...rest}
      $color={color}
      $backgroundColor={backgroundColor}
      $borderColor={borderColor}
      onClick={isDisabled ? null : onClick}
      $isDisabled={isDisabled}
    >
      <Icon src={icon} iconColor={iconColor} size={size || 16} />
    </ButtonContainer>
  );
});

export default IconButton;
