import React from "react";
import styled from "styled-components/macro";
// import theme from "theme";

const Container = styled.label`
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 27.5px */
  letter-spacing: 0.44px;
`;

const Label = ({ className, children }) => {
  return <Container className={className}>{children}</Container>;
};

export default Label;
