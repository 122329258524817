import { useEffect, useState } from "react";

import FoamsList from "components/general/FoamsList";
import { Helmet } from "react-helmet";
import Layout from "components//general/Layout";
import { LinkButton } from "components/general/Button";
// import welcomeImages from "images/welcome_images.png";
import ScreenshotPile from "components/welcome/ScreenshotPile";
import StepNumber from "components/general/StepNumber";
import Wrapper from "components/general/Wrapper";
import axios from "axios";
import imageWelcomePin from "images/img_welcome_pin.png";
import styled from "styled-components/macro";
import theme from "theme.js";
import useIsFoamyInstalled from "hooks/useIsFoamyInstalled";
import useIsFoamyPinned from "hooks/useIsFoamyPinned";

const StackContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 4}px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 20}px;
`;

const Container = styled.div`
  display: flex;
  column-gap: ${theme.spacing.unit * 12}px;
`;

const ScreenshotPileContainer = styled.div`
  width: 600px;
  // margin: ${theme.spacing.unit * 8}px 0;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 4}px;
`;

const SectionTitle = styled.div`
  align-self: flex-start;

  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 44.8px */
  letter-spacing: 0.64px;
`;

const Step = styled.div`
  display: flex;
  column-gap: ${theme.spacing.unit * 3}px;
  align-items: flex-start;
`;

const Direction = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 4}px;

  margin-top: 4px;

  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 30.8px */
  letter-spacing: 0.44px;
`;

const PinImage = styled.img`
  width: 350px;
  // height: 271px;
  border: 3px solid ${theme.colors.black};
  border-radius: 20px;
`;

const HowToButton = styled(LinkButton)`
  align-self: flex-start;
`;

function Welcome() {
  const isFoamyInstalled = useIsFoamyInstalled();
  const isFoamyPinned = useIsFoamyPinned();

  const [foams, setFoams] = useState(null);

  useEffect(() => {
    const getFoams = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/api/foams`,
        { withCredentials: true }
      );

      setFoams(response.data);
    };
    getFoams();
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Welcome to Foamy</title>
      </Helmet>
      <ContentContainer>
        <Wrapper>
          <StackContainer>
            <SectionTitle>Let's Get Started!</SectionTitle>
            <Container>
              <ScreenshotPileContainer>
                <ScreenshotPile />
              </ScreenshotPileContainer>
              <StepsContainer>
                <Step>
                  <StepNumber
                    backgroundColor={
                      isFoamyInstalled ? theme.colors.green : "transparent"
                    }
                  >
                    {1}
                  </StepNumber>
                  <Direction>Install the extension</Direction>
                </Step>
                <Step>
                  <StepNumber
                    backgroundColor={
                      isFoamyPinned ? theme.colors.green : "transparent"
                    }
                  >
                    {2}
                  </StepNumber>
                  <Direction>
                    Pin it for easy access
                    <PinImage src={imageWelcomePin} />
                  </Direction>
                </Step>
                <Step>
                  <StepNumber backgroundColor={"transparent"}>{3}</StepNumber>
                  <Direction>
                    Start watching a Foam below or create your own!
                    <HowToButton
                      backgroundColor={theme.colors.green}
                      path={"/foams/add"}
                    >
                      How do I create my own?
                    </HowToButton>
                  </Direction>
                </Step>
              </StepsContainer>
            </Container>
          </StackContainer>
        </Wrapper>
        {foams && <FoamsList foams={foams} />}
      </ContentContainer>
    </Layout>
  );
}

export default Welcome;
