import AuthContext from "AuthContext";
// import React from "react";
// import { Route } from "react-router-dom";
import { useContext } from "react";
// import { useAuth0 } from "@auth0/auth0-react";

const AdminRoute = ({ children }) => {
  // const { isAuthenticated } = useAuth0();
  const { user } = useContext(AuthContext);

  if (!user || user.type !== "admin") {
    return null;
  }

  return children;
};

export default AdminRoute;
