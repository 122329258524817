/* global chrome */

import { useEffect, useState } from "react";

const useIsFoamyPinned = () => {
  const [isFoamyPinned, setIsFoamyPinned] = useState(false);

  useEffect(() => {
    const checkFoamyPin = () => {
      try {
        chrome.runtime.sendMessage(
          process.env.REACT_APP_EXTENSION_ID,
          { type: "IS_FOAMY_PINNED" },
          (response) => {
            if (chrome.runtime.lastError) {
              // console.log("error: ", chrome.runtime.lastError);
              setIsFoamyPinned(false);
            } else {
              if (response) {
                setIsFoamyPinned(true);
              } else {
                setIsFoamyPinned(false);
              }
            }
          }
        );
      } catch (e) {
        // console.log(e);
        setIsFoamyPinned(false);
      }
    };

    // Initial check
    checkFoamyPin();

    // Set up interval to check every 2 seconds
    const intervalId = setInterval(checkFoamyPin, 2000);

    // Cleanup interval on hook unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return isFoamyPinned;
};

export default useIsFoamyPinned;
