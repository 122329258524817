import { Email } from "react-obfuscate-email";
import { Helmet } from "react-helmet";
import Layout from "components//general/Layout";
import Wrapper from "components/general/Wrapper";
import styled from "styled-components/macro";
import theme from "../theme.js";

const Container = styled.div`
  margin: ${theme.spacing.unit * 8}px 0;

  font-size: 16px;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.02em;
`;

function Terms() {
  return (
    <Layout>
      <Helmet>
        <title>Foamy Terms</title>
      </Helmet>
      <Wrapper>
        <Container>
          <h1>Terms and Conditions for Foamy Chrome Extension and Website</h1>
          <p>
            <strong>Last Updated: January 28th, 2024</strong>
          </p>
          <p>
            Welcome to Foamy, a Chrome extension designed to enhance your
            Netflix viewing experience by allowing you to overlay comments on
            videos. Additionally, our associated website offers features like
            account management and synchronization. Before you proceed, please
            read and agree to the following terms and conditions, which govern
            your use of the Foamy extension and website.
          </p>
          <ol>
            <li>
              <strong>Acceptance of Terms:</strong>
              <p>
                By installing and using the Foamy Chrome extension and accessing
                our website, you agree to comply with and be bound by these
                terms and conditions. If you do not agree to these terms, please
                do not use the extension or website.
              </p>
            </li>
            <li>
              <strong>License to Use:</strong>
              <p>
                We grant you a limited, non-exclusive, non-transferable license
                to use the Foamy extension and website solely for personal,
                non-commercial purposes. You may not modify, distribute, or
                create derivative works based on this extension or website.
              </p>
            </li>
            <li>
              <strong>User Account and Google Account Login:</strong>
              <p>
                To access certain features on the Foamy website, you may need to
                log in using your Google account. You are responsible for
                maintaining the confidentiality of your account information and
                agree to accept responsibility for all activities that occur
                under your account.
              </p>
            </li>
            <li>
              <strong>User Conduct:</strong>
              <p>
                You are solely responsible for any content you overlay on
                Netflix videos using the Foamy extension. Do not use the
                extension to display offensive, abusive, or inappropriate
                comments. We reserve the right to remove any content that
                violates these guidelines.
              </p>
            </li>
            <li>
              <strong>Privacy:</strong>
              <p>
                Foamy collects and stores minimal personal information necessary
                for account management and synchronization. Please review our
                Privacy Policy for details on how we handle your data.
              </p>
            </li>
            <li>
              <strong>Modification of Extension and Website:</strong>
              <p>
                We may, from time to time, release updates or modifications to
                the Foamy extension and website. You agree to install updates
                promptly to ensure the best possible experience and security.
              </p>
            </li>
            <li>
              <strong>Compatibility:</strong>
              <p>
                Foamy is designed to work with the latest version of the Google
                Chrome browser. We do not guarantee compatibility with other
                browsers or older versions of Chrome.
              </p>
            </li>
            <li>
              <strong>Intellectual Property:</strong>
              <p>
                All rights, title, and interest in and to the Foamy extension
                and website, including any intellectual property rights, are
                owned by us. You may not use our trademarks, logos, or other
                proprietary information without our prior written consent.
              </p>
            </li>
            <li>
              <strong>Termination:</strong>
              <p>
                We reserve the right to terminate or suspend your access to the
                Foamy extension and website at any time, without notice, for any
                reason. Upon termination, you must uninstall the extension.
              </p>
            </li>
            <li>
              <strong>Disclaimer of Warranty:</strong>
              <p>
                Foamy is provided "as is" without any warranty of any kind,
                either expressed or implied. We do not guarantee that the
                extension and website will be error-free or uninterrupted.
              </p>
            </li>
            <li>
              <strong>Limitation of Liability:</strong>
              <p>
                In no event shall we be liable for any direct, indirect,
                incidental, special, or consequential damages arising out of or
                in any way connected with the use of the Foamy extension or
                website.
              </p>
            </li>
            <li>
              <strong>Changes to Terms:</strong>
              <p>
                We reserve the right to modify or update these terms and
                conditions at any time. It is your responsibility to review
                these terms periodically. Continued use of the extension and
                website after changes constitutes acceptance of the updated
                terms.
              </p>
            </li>
            <li>
              <strong>Contact Information:</strong>
              <p>
                If you have any questions or concerns about these terms, please
                contact us at <Email email="support@foamy.video" />.
              </p>
            </li>
          </ol>
          <p>
            <em>
              By installing and using the Foamy Chrome extension and accessing
              our website, you acknowledge that you have read, understood, and
              agreed to these terms and conditions. Enjoy your enhanced Netflix
              viewing experience with Foamy!
            </em>
          </p>
        </Container>
      </Wrapper>
    </Layout>
  );
}

export default Terms;
