import { useEffect, useState } from "react";

import AuthContext from "AuthContext";
import Avatar from "components/general/Avatar";
import FoamRow from "components/private/profile/FoamRow";
import { Helmet } from "react-helmet";
import Layout from "components/general/Layout";
import { LinkButton } from "components/general/Button";
import Wrapper from "components/general/Wrapper";
import axios from "axios";
import styled from "styled-components/macro";
import theme from "theme.js";
import { useContext } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${theme.spacing.unit * 4}px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 5}px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 5}px;
`;

const MeContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.unit * 2}px;
`;

const Intro = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const TabsContainer = styled.div`
  display: flex;
  column-gap: ${theme.spacing.unit * 3}px;
`;

const NoFoamsMessage = styled.div`
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 27.5px */
  letter-spacing: 0.44px;
`;

function Profile() {
  const { user } = useContext(AuthContext);
  const [foams, setFoams] = useState(null);

  useEffect(() => {
    if (user) {
      const getFoams = async () => {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE}/api/users/id/${user.id}/foams`,
          {
            withCredentials: true,
          }
        );
        // console.log(response.data);

        let _foams = response.data;
        _foams.sort(
          (a, b) =>
            new Date(b.contentUpdatedAt).getTime() -
            new Date(a.contentUpdatedAt).getTime()
        );

        setFoams(_foams);
      };
      getFoams();
    }
  }, [user]);

  const handleDeleteClick = (foamId) => (e) => {
    console.log(foamId);
  };

  const updateFoamStatus = async (foamId, status) => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE}/api/foams/${foamId}`,
      {
        status,
      },
      {
        withCredentials: true,
      }
    );
    console.log(response.data);
  };

  const handlePrivacyChange = (foamId) => async (newStatus) => {
    await updateFoamStatus(foamId, newStatus);

    if (newStatus === "archived") {
      setFoams((prevFoams) => {
        return prevFoams.filter((foam) => foam.id !== foamId);
      });
    }
  };

  const handleUpdate = (foamId) => async (updatedFoam) => {
    const payload = {
      title: updatedFoam.title,
      description: updatedFoam.description,
      contentUpdatedAt: updatedFoam.contentUpdatedAt,
    };

    // Update the foam in the list we have with the payload values
    setFoams((prevFoams) => {
      return prevFoams.map((foam) => {
        if (foam.id === foamId) {
          return {
            ...foam,
            ...payload,
          };
        }
        return foam;
      });
    });
  };

  // const archiveFoam = (foamId) => async () => {
  //   const response = await axios.put(
  //     `${process.env.REACT_APP_API_BASE}/api/foams/${foamId}`,
  //     {
  //       status: "archived",
  //     },
  //     {
  //       withCredentials: true,
  //     }
  //   );
  //   console.log(response.data);

  //   // Remove from our foams
  //   setFoams((prevFoams) => {
  //     return prevFoams.filter((foam) => foam.id !== foamId);
  //   });
  // };

  return (
    <Layout>
      <Helmet>
        <title>Your Foamy Profile</title>
      </Helmet>
      {/* <Header /> */}
      <ContentContainer>
        <Wrapper>
          <HeaderContainer>
            <MeContainer>
              <Avatar avatar={user.avatar} />
              <Intro>{`Hi, @${user.username}`}</Intro>
            </MeContainer>
            <TabsContainer>
              <LinkButton
                path={"/profile"}
                width={144}
                backgroundColor={theme.colors.blue}
              >
                My Foams
              </LinkButton>
              <LinkButton path={"/account"} width={144}>
                Account
              </LinkButton>
            </TabsContainer>
          </HeaderContainer>
        </Wrapper>
        <Wrapper padding={0}>
          <Container>
            {foams && foams.length === 0 && (
              <NoFoamsMessage>
                You haven't created any foams yet!
              </NoFoamsMessage>
            )}
            {foams &&
              foams.map((foam) => (
                <FoamRow
                  key={foam.id}
                  foam={foam}
                  onUpdate={handleUpdate(foam.id)}
                  onPrivacyChange={handlePrivacyChange(foam.id)}
                  onDeleteClick={handleDeleteClick(foam.id)}
                />
              ))}
          </Container>
        </Wrapper>
      </ContentContainer>
    </Layout>
  );
}

export default Profile;
