import React from "react";
import styled from "styled-components/macro";
import theme from "theme";

const Container = styled.div`
  overflow: hidden;
  border: 3px solid ${theme.colors.black};
  border-radius: 16px;
`;

const Image = styled.img`
  display: block;

  width: 100%;
`;

const Filter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
`;

export const Screenshot = ({ className, src, hasFilter }) => {
  return (
    <Container className={className}>
      <Image src={src} alt={"Screenshot"} />
      {hasFilter && <Filter />}
    </Container>
  );
};

export default Screenshot;
