import { FocusOn } from "react-focus-on";
// import Icon from "components/general/Icon";
import React from "react";
import { createPortal } from "react-dom";
// import iconBack from "images/ic_arrow_left.png";
// import iconClose from "images/ic_close.png";
import styled from "styled-components/macro";
import theme from "theme";

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: ${(props) => (props.$isVisible ? "block" : "none")};
  background-color: rgba(44, 44, 44, 0.5);

  ${(props) =>
    props.$hasBackgroundBlur &&
    `backdrop-filter: blur(2px);
     -webkit-backdrop-filter: blur(2px);`}
`;

const ScrollableContainer = styled.div`
  overflow: auto;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: ${theme.spacing.unit * 4}px ${theme.spacing.unit * 2}px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Container = styled.div`
  box-sizing: border-box;
  width: ${(props) => (props.$isLarge ? 686 : 440)}px;
  margin: auto;
  padding: ${theme.spacing.unit * 4}px;
  background: ${theme.colors.white};
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  color: ${theme.colors.black};
  border: 3px solid ${theme.colors.black};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${theme.spacing.unit * 4}px;
`;

// const LeftButton = styled.button`
//   cursor: pointer;
// `;

const Content = styled.div`
  padding: ${theme.spacing.unit * 2}px;
`;

const Dialog = ({
  isLarge,
  isVisible,
  children,
  hasBack,
  onBackClick,
  onCloseClick,
  isEmbed,
  hideClose,
  headerRightItem,
  isBackgroundCloseDisabled,
  hasBackgroundBlur,
}) => {
  const handleScrollContainerClick = (e) => {
    if (onCloseClick && !isBackgroundCloseDisabled) {
      onCloseClick();
    }
    e.stopPropagation();
  };

  const container = () => {
    let leftItem = <div />;
    // if (hasBack) {
    //   leftItem = (
    //     <LeftButton onClick={onBackClick}>
    //       <Icon src={iconBack} />
    //     </LeftButton>
    //   );
    // } else if (!isEmbed && !hideClose) {
    //   leftItem = (
    //     <LeftButton onClick={onCloseClick}>
    //       <Icon src={iconClose} />
    //     </LeftButton>
    //   );
    // }

    return (
      <FocusOn
        enabled={isVisible}
        onClickOutside={() =>
          onCloseClick && !isBackgroundCloseDisabled ? onCloseClick() : null
        }
        onEscapeKey={() => (onCloseClick ? onCloseClick() : null)}
        // gapMode={"padding"}
      >
        <ScrollableContainer onClick={handleScrollContainerClick}>
          <Container $isLarge={isLarge} onClick={(e) => e.stopPropagation()}>
            <Header>
              {leftItem}
              {headerRightItem}
            </Header>
            <Content>{children}</Content>
          </Container>
        </ScrollableContainer>
      </FocusOn>
    );
  };

  return (
    <>
      {isEmbed ? (
        container
      ) : (
        <>
          {createPortal(
            <OverlayContainer
              $isVisible={isVisible}
              $hasBackgroundBlur={hasBackgroundBlur}
            >
              {container()}
            </OverlayContainer>,
            document.body
          )}
        </>
      )}
    </>
  );
};

export default Dialog;
