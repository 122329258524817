import React, { useEffect, useState } from "react";

import AuthContext from "AuthContext";
import axios from "axios";

const AuthProvider = ({ children }) => {
  const [showLogin, setShowLogin] = useState(false);
  const [user, setUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);

  const setUserContext = (userData) => {
    setUser(userData);
  };

  useEffect(() => {
    const getMe = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE}/api/users/me`,
          { withCredentials: true }
        );
        console.log(response.data);
        // setUser(response.data);
        setUserContext(response.data);
      } catch (err) {
        console.log(err);
      } finally {
        setIsUserLoading(false);
      }
    };
    getMe();
  }, []);

  const logout = async () => {
    // Post logout and wait for response
    await axios.post(
      `${process.env.REACT_APP_API_BASE}/api/auth/logout`,
      {},
      { withCredentials: true }
    );

    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isUserLoading,
        setUserContext,
        showLogin,
        setShowLogin,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
