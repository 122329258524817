import AuthContext from "AuthContext";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout() {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const _logout = async () => {
      await logout();
      navigate("/");
    };
    _logout();
  }, [logout, navigate]);
}

export default Logout;
