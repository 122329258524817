import styled, { css } from "styled-components/macro";

import Avatar from "components/general/Avatar";
import { Link } from "react-router-dom";
import theme from "theme";

const Container = styled(Link)`
  display: inline-flex;
  align-items: center;
  column-gap: ${theme.spacing.unit * 2}px;

  text-decoration: none;
  color: black;

  img {
    box-shadow: 2px 2px ${(props) => props.$borderColor || theme.colors.black};

    transition: 0.1s ease-in-out;
  }

  &:hover {
    img {
      transform: translate(-1px, -1px);
      box-shadow: 3px 3px ${(props) => props.$borderColor || theme.colors.black};
    }

    // span {
    //   text-decoration: underline;
    // }
  }

  &:active {
    img {
      transform: translate(1px, 1px);
      box-shadow: 1px 1px ${(props) => props.$borderColor || theme.colors.black};
    }
  }
`;

const Username = styled.span`
  ${(props) =>
    props.$isSubtle
      ? css`
          font-size: 16px;
          font-weight: 300;
          line-height: 19px;
          letter-spacing: 0em;
        `
      : css`
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        `}
`;

const UserLink = ({ className, foam, isSubtle }) => {
  if (!foam || !foam.user.username || !foam.user.avatar) {
    return null;
  }

  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Container
      className={className}
      to={`/@${foam.user.username}`}
      onClick={handleClick}
    >
      <Avatar avatar={foam.user.avatar} size={isSubtle ? 32 : 40} />
      <Username $isSubtle={isSubtle}>@{foam.user.username}</Username>
    </Container>
  );
};

export default UserLink;
