import { ExternalLinkButton } from "components/general/Button";
import React from "react";
import styled from "styled-components/macro";
import theme from "theme";

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.unit * 1}px;
`;

const NetflixButton = ({ onClick }) => {
  return (
    <ExternalLinkButton
      url={"https://www.netflix.com/"}
      size={"large"}
      width={280}
      color={theme.colors.white}
      backgroundColor={theme.colors.netflixRed}
      shouldOpenInNewTab={true}
    >
      <Container>Launch Netflix</Container>
    </ExternalLinkButton>
  );
};

export default NetflixButton;
