import FooterDownloadButton from "components/buttons/FooterDownloadButton";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import theme from "theme.js";

const Container = styled.div`
  height: 160px;
  background-color: ${theme.colors.darkGray};
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.unit * 5}px ${theme.spacing.unit * 8}px;
  box-sizing: border-box;

  ${theme.media.phone`
    flex-direction: column;
    row-gap: ${theme.spacing.unit * 4}px;
    height: auto;
    padding-left: ${theme.spacing.unit * 4}px;
    padding-right: ${theme.spacing.unit * 4}px;
  `}
`;

const RightContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: ${theme.spacing.unit * 2}px;

  ${theme.media.phone`
    // flex-direction: column;
    // align-self: center;
    // justify-content: center;
    row-gap: ${theme.spacing.unit * 4}px;
  `}
`;

const Copyright = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: ${theme.colors.white};

  opacity: 0.5;
`;

const LinksContainer = styled.div`
  align-self: flex-end;
  display: flex;
  column-gap: ${theme.spacing.unit * 6}px;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;

  a {
    text-decoration: none;
    color: ${theme.colors.white};
  }

  ${theme.media.phone`
    text-align: center;
    flex-direction: column;
    align-self: center;
    justify-content: flex-start;
    row-gap: ${theme.spacing.unit * 4}px;
  `}
`;

const AttributionLink = styled.a`
  text-decoration: underline;
`;

const Footer = (props) => {
  return (
    <Container>
      <FooterDownloadButton />
      <RightContainer>
        <LinksContainer>
          <Link to="/support">Support</Link>
          <Link to="/terms">Terms of Use</Link>
          <Link to="/privacy">Privacy Policy</Link>
        </LinksContainer>
        <Copyright>
          © Foamy Video. Made with love in Brooklyn ♡. Backdrop images from{" "}
          <AttributionLink href={"https://www.themoviedb.org/"}>
            TMDB
          </AttributionLink>
          .
        </Copyright>
      </RightContainer>
    </Container>
  );
};

export default Footer;
