import React from "react";
import styled from "styled-components/macro";
import theme from "theme";

const Container = styled.div`
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 44px;
  height: 44px;

  border-radius: 50%;
  border: 3px solid ${theme.colors.black};

  background-color: ${(props) =>
    props.$backgroundColor ? props.$backgroundColor : theme.colors.green};

  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 30.8px */
  letter-spacing: 0.44px;
`;

const StepNumber = ({ className, children, backgroundColor }) => {
  return (
    <Container className={className} $backgroundColor={backgroundColor}>
      {children}
    </Container>
  );
};

export default StepNumber;
