import { useEffect, useState } from "react";

import theme from "theme.js";

const useIsPhone = () => {
  const [isPhone, setIsPhone] = useState(false);

  useEffect(() => {
    const checkIsPhone = () => {
      const phoneMediaQuery = theme.media.phoneQuery;

      setIsPhone(phoneMediaQuery.matches);

      phoneMediaQuery.onchange = handlePhoneMediaQueryChange;
    };

    checkIsPhone();
  }, []);

  const handlePhoneMediaQueryChange = (phoneMediaQuery) => {
    setIsPhone(phoneMediaQuery.matches);
  };

  return isPhone;
};

export default useIsPhone;
