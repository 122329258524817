import { Email } from "react-obfuscate-email";
import { Helmet } from "react-helmet";
import Layout from "components//general/Layout";
import Wrapper from "components/general/Wrapper";
import styled from "styled-components/macro";
import theme from "../theme.js";

const Container = styled.div`
  margin: ${theme.spacing.unit * 8}px 0;

  font-size: 16px;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.02em;
`;

function Privacy() {
  return (
    <Layout>
      <Helmet>
        <title>Foamy Privacy</title>
      </Helmet>
      <Wrapper>
        <Container>
          <h1>Privacy Policy for Foamy Chrome Extension and Website</h1>
          <p>
            <strong>Last Updated: January 28th, 2024</strong>
          </p>
          <p>
            This Privacy Policy outlines how we collect, use, and protect your
            personal information when you use the Foamy Chrome extension and
            access our website. By using the extension and website, you consent
            to the practices described in this policy.
          </p>
          <ol>
            <li>
              <strong>Information We Collect:</strong>
              <p>
                Foamy collects minimal personal information necessary for
                account management and synchronization. This may include your
                Google account information for authentication purposes.
              </p>
            </li>
            <li>
              <strong>How We Use Your Information:</strong>
              <p>
                We use the collected information to provide and improve the
                Foamy extension and website, personalize your experience, and
                sync your preferences across devices.
              </p>
            </li>
            <li>
              <strong>Third-Party Services:</strong>
              <p>
                We may use third-party services for analytics and
                authentication. These services may collect information according
                to their respective privacy policies.
              </p>
            </li>
          </ol>
          <p>
            <strong>Security:</strong> We implement reasonable security measures
            to protect your personal information from unauthorized access,
            disclosure, alteration, and destruction.
          </p>
          <p>
            <strong>Changes to Privacy Policy:</strong> We reserve the right to
            modify or update this Privacy Policy at any time. We will notify you
            of any changes by posting the new policy on this page.
          </p>
          <p>
            <strong>Contact Information:</strong> If you have any questions or
            concerns about this Privacy Policy, please contact us at{" "}
            <Email email="support@foamy.video" />.
          </p>
          <p>
            <em>
              By using the Foamy Chrome extension and accessing our website, you
              acknowledge that you have read, understood, and agreed to this
              Privacy Policy.
            </em>
          </p>
        </Container>
      </Wrapper>
    </Layout>
  );
}

export default Privacy;
