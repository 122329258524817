import * as React from "react";

import Button from "components/general/Button";
import Icon from "components/general/Icon";
import iconDown from "images/ic_down.png";
import styled from "styled-components/macro";
import theme from "theme.js";
import { useSelect } from "downshift";

const Container = styled.div`
  position: relative;
  width: ${(props) => props.$width || 120}px;
`;

const ButtonContentContainer = styled.div`
  display: flex;
  column-gap: ${theme.spacing.unit * 1}px;
  align-items: center;
`;

const OptionsContainer = styled.ul`
  z-index: 100;

  display: ${(props) => (props.$isVisible ? "flex" : "none")};

  // width: ${(props) => props.$width || 120}px;
  width: 240px;

  transform: translateY(16px);

  // top: 16px;
  right: 0;

  position: absolute;
  border: 3px solid ${theme.colors.black};
  border-radius: 16px;

  list-style: none;

  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;

  padding: ${theme.spacing.unit * 1}px;

  flex-direction: column;
  row-gap: ${theme.spacing.unit * 1}px;

  background-color: ${theme.colors.white};
`;

const Option = styled.li`
  border-radius: 16px;
  // height: 48px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // row-gap: ${theme.spacing.unit * 1}px;
  // justify-content: center;
  padding: ${theme.spacing.unit * 2}px;

  ${(props) =>
    props.$isSelected && `background-color: ${props.$highlightColor}`};

  ${(props) =>
    props.$isHighlighted && `background-color: ${props.$highlightColor}`};
`;

const Label = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Description = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 128.889%;
`;

const Select = ({ width, options, selectedOption, onOptionChange }) => {
  const handleOptionChange = (change) => {
    console.log(change);
    onOptionChange(change.selectedItem.value);
  };

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: options,
    initialSelectedItem: selectedOption,
    onSelectedItemChange: handleOptionChange,
  });

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  console.log(highlightedIndex);
  console.log(selectedItem);

  return (
    <Container $width={width} onClick={handleClick}>
      <Button {...getToggleButtonProps()} size={"small"} isFullWidth={true}>
        <ButtonContentContainer>
          {selectedItem?.label}
          <Icon size={16} src={iconDown} alt={"Play Icon"} />
        </ButtonContentContainer>
      </Button>
      <OptionsContainer {...getMenuProps()} $width={width} $isVisible={isOpen}>
        {isOpen &&
          options.map((item, index) => (
            <Option
              key={item.value}
              {...getItemProps({
                item,
                index,
              })}
              $isSelected={selectedItem.value === item.value}
              $isHighlighted={highlightedIndex === index}
              $highlightColor={
                item.highlightColor ? item.highlightColor : theme.colors.yellow
              }
            >
              <Label>{item.label}</Label>
              <Description>{item.description}</Description>
            </Option>
          ))}
      </OptionsContainer>
    </Container>
  );
};

export default Select;
