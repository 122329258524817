import React, { useContext, useEffect } from "react";

import AuthContext from "AuthContext";
import { GoogleLogin } from "@react-oauth/google";
import { Helmet } from "react-helmet";
import Label from "components/general/Label";
import Layout from "components//general/Layout";
import LoginForm from "components/LoginForm";
import Wrapper from "components/general/Wrapper";
import axios from "axios";
import styled from "styled-components/macro";
import theme from "theme";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 4}px;
`;

const GoogleInputGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 1}px;
`;

const Login = (props) => {
  const { user, setUserContext } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.username && user.avatar) {
      navigate("/");
    }
  }, [user, navigate]);

  const handleSave = (newUser) => {
    setUserContext(newUser);

    // Redirect to the homepage
    navigate("/");
  };

  const verifyGoogleCredentials = async (credentialResponse) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE}/api/auth/google/verify`,
      credentialResponse,
      { withCredentials: true }
    );
    console.log(response.data); // This should be the user

    // // Store the access token in local storage
    // const authDataString = JSON.stringify(response.data);

    // localStorage.setItem("authData", authDataString);

    const user = response.data;

    setUserContext(user);

    // Redirect to the homepage if they have a username
    if (user?.username) {
      navigate("/");
    }
  };

  return (
    <Layout hideLoginModal={true}>
      <Helmet>
        <title>Log in to Foamy</title>
      </Helmet>
      {/* <Header hideLoginModal={true} /> */}
      <Wrapper width={434}>
        <Container>
          {user && (!user.username || !user.avatar) ? (
            <LoginForm onSave={handleSave} />
          ) : (
            <GoogleInputGroupContainer>
              <Label>Log in or Sign up</Label>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  console.log(credentialResponse);
                  verifyGoogleCredentials(credentialResponse);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
                size={"large"}
                text={"continue_with"}
                width={338}
              />
            </GoogleInputGroupContainer>
          )}
        </Container>
      </Wrapper>
      {/* <Footer /> */}
    </Layout>
  );
};

export default Login;
