import Label from "components/general/Label";
import React from "react";
import styled from "styled-components/macro";
import theme from "theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 1}px;

  max-width: 100%;
`;

const StyledInput = styled.input`
  height: 56px;
  width: 100%;
  background: ${theme.colors.white};
  box-sizing: border-box;
  padding: 0 ${theme.spacing.unit * 2}px;

  // Border
  border: 3px solid
    ${(props) =>
      props.$hasError ? `${theme.colors.red}` : `${theme.colors.black}`};
  border-radius: 16px;

  // Focus (would like to do something cool here)
  // outline: none;
  // &:focus {
  //   border: 3px solid ${theme.colors.blue};
  // }

  // Placeholders
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${(props) =>
      props.$hasError ? `${theme.colors.red}` : `${theme.colors.mediumGray}`};
  }
  :-ms-input-placeholder {
    color: ${(props) =>
      props.$hasError ? `${theme.colors.red}` : `${theme.colors.mediumGray}`};
  }

  // Text
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 28px */
  letter-spacing: 0.4px;
  color: ${(props) =>
    props.$hasError ? `${theme.colors.red}` : `${theme.colors.black}`};
`;

const Error = styled.div`
  // Text
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 128.889%;
  color: ${theme.colors.red};
`;

const Input = (props) => {
  const { className, label, error, isTouched, ...rest } = props;

  const hasError = error && isTouched;

  return (
    <Container className={className}>
      {label && <Label>{label}</Label>}
      <StyledInput {...rest} $hasError={hasError}></StyledInput>
      {hasError && <Error>{error}</Error>}
    </Container>
  );
};

export default Input;
