import AuthContext from "AuthContext";
// import React from "react";
// import { Route } from "react-router-dom";
import { useContext } from "react";
// import { useAuth0 } from "@auth0/auth0-react";

const PrivateRoute = ({ children }) => {
  // const { isAuthenticated } = useAuth0();
  const { user, isUserLoading } = useContext(AuthContext);

  if (isUserLoading) {
    return null;
  } else if (!user) {
    return <div>Unauthorized</div>;
  }

  return children;
};

export default PrivateRoute;
