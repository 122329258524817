import Layout from "components//general/Layout";
import { Link } from "react-router-dom";
import PageTitle from "components/general/PageTitle";
import Wrapper from "components/general/Wrapper";
import styled from "styled-components/macro";
import theme from "theme.js";

const Container = styled.div`
  margin-top: ${theme.spacing.unit * 8}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

function Home() {
  return (
    <Layout>
      <Wrapper>
        <PageTitle>Home</PageTitle>
        <Container>
          <div>admin home</div>
          <Link to="/admin/images/add">Add image</Link>
        </Container>
      </Wrapper>
    </Layout>
  );
}

export default Home;
