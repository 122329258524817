import React from "react";
import { hexToCSSFilter } from "hex-to-css-filter";
import styled from "styled-components/macro";
import tinycolor from "tinycolor2";

const Image = styled.img`
  display: block;
  ${(props) =>
    props.$iconColor &&
    `filter: brightness(0) saturate(100%) ${
      hexToCSSFilter(props.$iconColor).filter
    };`};
`;

const Icon = ({
  className,
  src,
  iconColor,
  isSmall,
  isLarge,
  isExtraLarge,
  size,
}) => {
  let width = 24;
  let height = 24;
  if (isSmall) {
    width = 16;
    height = 16;
  } else if (isLarge) {
    width = 36;
    height = 36;
  } else if (isExtraLarge) {
    width = 48;
    height = 48;
  } else if (size) {
    width = size;
    height = size;
  }

  // Convert color to hex
  let hexIconColor = iconColor;
  if (iconColor) {
    hexIconColor = tinycolor(iconColor).toHexString();
  }

  return (
    <Image
      className={className}
      $iconColor={hexIconColor}
      src={src}
      width={width}
      height={height}
      alt=""
    />
  );
};

export default Icon;
