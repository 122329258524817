import { useEffect, useState } from "react";

import AuthContext from "AuthContext";
import AvatarSelect from "components/general/AvatarSelect";
import Button from "components/general/Button";
import Input from "components/general/Input";
import Label from "components/general/Label";
import axios from "axios";
import styled from "styled-components/macro";
import theme from "theme.js";
import toast from "react-hot-toast";
import { useContext } from "react";
import { useDebouncedCallback } from "use-debounce";

const AvatarInputGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 1}px;
`;

function LoginForm({ onSave }) {
  const { user } = useContext(AuthContext);

  const [username, setUsername] = useState(user?.username);
  const [usernameError, setUsernameError] = useState(null);
  const [avatar, setAvatar] = useState(user?.avatar);

  useEffect(() => {
    if (user) {
      setUsername(user.username);
      setAvatar(user.avatar);
    }
  }, [user]);

  // Debounce callback
  const debounced = useDebouncedCallback(
    // function
    async (value) => {
      // Check if this username is already taken
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/api/users/username/${value}/taken`,
        {
          withCredentials: true,
        }
      );
      // console.log(response.data);

      if (response.data.taken === true) {
        setUsernameError("This username is already taken");
      } else if (response.data.taken === false) {
        setUsernameError(null);
      }
    },
    // delay in ms
    500
  );

  const handleUsernameChange = (e) => {
    console.log("Calling handle username change function");

    console.log(e.target.value);

    const maxUsernameLength = 30;
    if (e.target.value.length > maxUsernameLength) {
      return;
    }

    setUsername(e.target.value);

    if (e.target.value && e.target.value !== user.username) {
      debounced(e.target.value);
    }
  };

  const handleAvatarChange = (avatar) => {
    setAvatar(avatar);
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE}/api/users/me`,
        {
          username,
          avatarId: avatar.id,
        },
        {
          withCredentials: true,
        }
      );

      onSave(response.data);
    } catch (error) {
      console.error(error);

      const errorText = error.response?.data?.error || error.message;

      toast.error(errorText, {
        style: {
          border: `3px solid ${theme.colors.black}`,
          // borderRadius: "16px",
          borderRadius: "128px",
          backgroundColor: theme.colors.pink,
        },
      });
    }
  };

  return (
    <>
      <Input
        placeholder={"coolperson123"}
        label={"Change your Username"}
        value={username}
        onChange={handleUsernameChange}
        isTouched={true}
        error={usernameError}
      />
      <AvatarInputGroupContainer>
        <Label>Change your avatar</Label>
        <AvatarSelect
          selectedAvatar={avatar}
          onAvatarChange={handleAvatarChange}
        />
      </AvatarInputGroupContainer>
      <Button
        backgroundColor={theme.colors.green}
        onClick={handleSaveClick}
        isDisabled={!username || !avatar || usernameError}
        // isDisabled={!username || !avatar}
      >
        Save
      </Button>
    </>
  );
}

export default LoginForm;
