import { useCallback, useState } from "react";

import Button from "components/general/Button";
import ConfirmModal from "components/general/ConfirmModal";
import FoamDate from "components/general/FoamDate";
import Icon from "components/general/Icon";
import IconButton from "components/general/IconButton";
import { Link } from "react-router-dom";
import PlainTextInput from "components/private/profile/PlainTextInput";
import Popcorn from "components/general/Popcorn";
import RichTextInput from "components/private/profile/RichTextInput";
import RichTextReader from "components/general/RichTextReader";
import Select from "components/general/Select";
import ShareButton from "components/buttons/ShareButton";
import axios from "axios";
import iconDelete from "images/ic_delete.png";
import iconPlayLarge from "images/ic_play_large.png";
import styled from "styled-components/macro";
import theme from "theme";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  position: relative;

  width: 100%;
  display: flex;
  // align-items: center;
  column-gap: ${theme.spacing.unit * 6}px;

  text-decoration: none;

  color: black;

  justify-content: space-between;

  border-radius: 24px;
  padding: ${theme.spacing.unit * 6}px;

  ${(props) =>
    !props.$isEditing &&
    `
    &:hover {
      background: #fcfbf8;
    }
    cursor: pointer;
  `}

  ${theme.media.phone`
    flex-direction: column;
    row-gap: ${theme.spacing.unit * 2}px;

    padding: ${theme.spacing.unit * 4}px;
  `}
`;

const DeleteButtonContainer = styled.div`
  // position: absolute;
  // left: 0;
  // position: absolute;
  // top: 50%; /* Move the top edge of the child to the middle of the parent */
  // transform: translate(-50%, -50%);

  // ${theme.media.phone`
  //   display: none;
  // `}

  position: absolute;
  top: 50%; /* Move the top edge of the child to the middle of the parent */
  left: 50%; /* Move the left edge of the child to the middle of the parent */
  transform: translate(-50%, -50%);
`;

const DeleteButton = styled(IconButton)`
  // position: absolute;
  // left: 0;
  // position: absolute;
  // top: 50%; /* Move the top edge of the child to the middle of the parent */
  // transform: translate(-50%, -50%);
`;

const LeftContainer = styled.div`
  flex: 1;

  display: flex;
  // align-items: center;
  column-gap: ${theme.spacing.unit * 6}px;

  ${theme.media.phone`
    flex-direction: column;
    row-gap: ${theme.spacing.unit * 2}px;
  `}
`;

const ThumbnailContainer = styled.div`
  flex: 1.2;

  position: relative;

  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 2}px;
`;

const ThumbnailLink = styled.a`
  display: block;
  position: relative;
`;

const Thumbnail = styled.img`
  display: block;
  overflow: hidden;

  // width: 352px;
  // height: 167px;
  width: 100%;

  border-radius: 14px;
  border: 2px solid #000;

  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;

  max-width: 100%;
  min-width: 0;

  ${theme.media.phone`
    width: 100%;
  `}
`;

const PopcornContainer = styled.div`
  position: absolute;
  right: -8px;
  top: -8px;
`;

const PlayIcon = styled(Icon)`
  display: none;

  position: absolute;
  top: 50%; /* Move the top edge of the child to the middle of the parent */
  left: 50%; /* Move the left edge of the child to the middle of the parent */
  transform: translate(-50%, -50%);

  opacity: 0.8;

  ${ThumbnailLink}:hover & {
    display: block;
  }
`;

const BubbleCount = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.42px;
`;

const DetailsContainer = styled.div`
  flex: 2;

  align-self: flex-start;

  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 2}px;

  ${theme.media.phone`
    width: 100%;
  `}
`;

const VideoTitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.02em;
`;

const Title = styled(RichTextReader)`
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 27.5px */
  letter-spacing: 0.44px;
`;

const Description = styled(RichTextReader)`
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 128.889%;
`;

// const ShareContainer = styled.div`
//   display: flex;
//   column-gap: ${theme.spacing.unit * 2}px;
//   align-items: center;
//   justify-content: center;
// `;

// const PrivacyStatus = styled.div`
//   // display: inline-block;
//   // align-self: flex-start;
//   padding: ${theme.spacing.unit * 1}px;
//   background-color: ${(props) => props.$backgroundColor};
//   // text-align: center;
//   border-radius: ${theme.spacing.unit * 1}px;

//   // font-size: 16px;
//   // font-style: normal;
//   // font-weight: 300;
//   // line-height: 128.889%;

//   font-size: 16px;
//   font-weight: 300;
//   line-height: 19px;
//   letter-spacing: 0em;

//   // opacity: 0.6;
// `;

const EditorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 1}px;
`;

const TitleTextInput = styled(PlainTextInput)`
  // margin: 0 -${theme.spacing.unit * 2}px;

  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 27.5px */
  letter-spacing: 0.44px;

  ${theme.media.phone`
    margin: 0;
  `}
`;

const StyledFoamDate = styled(FoamDate)`
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;

  opacity: 0.6;
`;

const DescriptionTextInput = styled(RichTextInput)`
  // margin: 0 -${theme.spacing.unit * 2}px;

  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 128.889%;

  ${theme.media.phone`
    margin: 0;
  `}
`;

const VerticalDivider = styled.div`
  width: 3px;
  background: #edeae1;
  min-height: 100%;
  margin: ${theme.spacing.unit * 3}px 0;

  ${theme.media.phone`
    display: none;
  `}
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: ${theme.spacing.unit * 2}px;

  ${theme.media.phone`
    justify-content: center;
  `}
`;

const FoamRow = ({
  className,
  foam,
  // onTitleChange,
  // onDescriptionChange,
  // onSaveClick,
  onUpdate,
  onPrivacyChange,
}) => {
  const navigate = useNavigate();

  const [title, setTitle] = useState(foam.title);
  const [description, setDescription] = useState(foam.description);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteConfirmModalVisible, setIsDeleteConfirmModalVisible] =
    useState(false);
  const [status, setStatus] = useState(foam.status);

  const handleEditClick = (e) => {
    console.log("Edit clicked");

    e.preventDefault();
    e.stopPropagation();

    setIsEditing(true);
  };

  // Why does this work? Without the callback the editor gets rebuilt every time
  const handleTitleChange = useCallback((newTitle) => {
    console.log(newTitle);
    // onTitleChange(newTitle);
    setTitle(newTitle);
  }, []);

  // const handleBodyChange = useCallback((newBody) => {
  //   setBody(newBody);
  // }, []);

  const handleDescriptionChange = useCallback((newDescription) => {
    console.log(newDescription);
    // onDescriptionChange(newDescription);
    setDescription(newDescription);
  }, []);

  const handleCancelClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsEditing(false);
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      title,
      description,
    };

    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE}/api/foams/${foam.id}`,
      payload,
      {
        withCredentials: true,
      }
    );
    console.log(response.data);

    // This should be the updated foam
    onUpdate(response.data);

    setIsEditing(false);

    toast("Foam updated!", {
      style: {
        border: `3px solid ${theme.colors.black}`,
        // borderRadius: "16px",
        borderRadius: "128px",
        backgroundColor: theme.colors.green,
      },
    });
  };

  const privacyOptions = [
    {
      value: "private",
      label: "Private",
      description: "Link is required to watch",
    },
    {
      value: "public",
      label: "Public",
      description: "Can appear on Foamy",
      // highlightColor: theme.colors.green,
    },
  ];

  const selectedOption = privacyOptions.find(
    (option) => option.value === status
  );

  const handleDeleteConfirm = () => {
    onPrivacyChange("archived");
    setIsDeleteConfirmModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteConfirmModalVisible(false);
  };

  const handlePrivacyChange = (newStatus) => {
    // Want to show a warning modal if the user selects "Delete"
    if (newStatus === "archived") {
      setIsDeleteConfirmModalVisible(true);
    } else {
      onPrivacyChange(newStatus);

      if (newStatus === "public") {
        toast("Thanks for contributing!", {
          style: {
            border: `3px solid ${theme.colors.black}`,
            borderRadius: "128px",
            backgroundColor: theme.colors.green,
          },
        });
      }

      setStatus(newStatus);
    }
  };

  let videoTitle = foam.video.title;
  if (foam.video.type === "episode") {
    videoTitle += ` (${foam.video.episodeTitle})`;
  }

  const handleClick = (e) => {
    navigate(`/foams/${foam.id}`);
  };

  return (
    <>
      <ConfirmModal
        isVisible={isDeleteConfirmModalVisible}
        title={"Are you sure you want to delete this foam?"}
        confirmButtonLabel={"OK"}
        onConfirm={handleDeleteConfirm}
        cancelButtonLabel={"Cancel"}
        onCancel={handleDeleteCancel}
        isWarning
        hasBackgroundBlur={true}
      />
      <Container
        className={className}
        onClick={isEditing ? null : handleClick}
        $isEditing={isEditing}
      >
        <LeftContainer>
          <ThumbnailContainer>
            <ThumbnailLink
              href={isEditing ? null : `/foams/${foam.id}/watch`}
              target={"_blank"}
              rel={"noreferrer"}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Thumbnail src={foam.video.thumbnailUrl} />
              <PopcornContainer>
                <Popcorn>
                  <BubbleCount>{foam.bubbleCount}</BubbleCount>
                </Popcorn>
              </PopcornContainer>
              {isEditing ? (
                <DeleteButtonContainer>
                  <DeleteButton
                    icon={iconDelete}
                    size={24}
                    onClick={() => handlePrivacyChange("archived")}
                    backgroundColor={theme.colors.pink}
                  />
                </DeleteButtonContainer>
              ) : (
                <PlayIcon
                  src={iconPlayLarge}
                  size={96}
                  iconColor={theme.colors.white}
                />
              )}
            </ThumbnailLink>
            {/* {isEditing && (
              <DeleteButtonContainer>
                <DeleteButton
                  icon={iconDelete}
                  size={24}
                  onClick={() => handlePrivacyChange("archived")}
                  backgroundColor={theme.colors.pink}
                />
              </DeleteButtonContainer>
            )} */}
            {/* <ShareContainer>
              <PrivacyStatus
                $backgroundColor={
                  status === "public" ? theme.colors.green : theme.colors.yellow
                }
              >
                {status === "public"
                  ? "Discoverable on Foamy"
                  : "Link is required to watch"}
              </PrivacyStatus>
              <ShareButton url={`${window.location.origin}/foams/${foam.id}`} />
            </ShareContainer> */}
          </ThumbnailContainer>
          <DetailsContainer>
            <Link to={`/foams/${foam.id}`} onClick={(e) => e.stopPropagation()}>
              <VideoTitle>{videoTitle}</VideoTitle>
            </Link>
            {/* <InfoContainer>
              <VideoInfoContainer>
                <Title>{foam.video.title}</Title>
                {foam.video.type === "episode" && (
                  <EpisodeTitle>{foam.video.episodeTitle}</EpisodeTitle>
                )}
              </VideoInfoContainer>
              <Divider /> */}
            {/* </InfoContainer> */}

            {isEditing ? (
              <EditorsContainer>
                <TitleTextInput
                  placeholder={"Add a title"}
                  text={title}
                  onTextChange={handleTitleChange}
                />
                <DescriptionTextInput
                  placeholder={"Add a description"}
                  text={description}
                  onTextChange={handleDescriptionChange}
                />
              </EditorsContainer>
            ) : (
              <>
                {foam.title && (
                  <Link
                    to={`/foams/${foam.id}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Title value={foam.title} />
                  </Link>
                )}
                <StyledFoamDate foam={foam} />
                {foam.description && <Description value={foam.description} />}
                {/* <ShareContainer>
                  <PrivacyStatus
                    $backgroundColor={
                      status === "public"
                        ? theme.colors.green
                        : theme.colors.yellow
                    }
                  >
                    {status === "public"
                      ? "Discoverable on Foamy"
                      : "Link is required to watch"}
                  </PrivacyStatus>
                  <ShareButtonContainer>
                    <IconShareButton
                      url={`${window.location.origin}/foams/${foam.id}`}
                    />
                  </ShareButtonContainer>
                </ShareContainer> */}
                {/* <PrivacyStatus
                  $backgroundColor={
                    status === "public"
                      ? theme.colors.green
                      : theme.colors.yellow
                  }
                >
                  {status === "public"
                    ? "Discoverable on Foamy"
                    : "Link is required to watch"}
                </PrivacyStatus>
                <ShareButton
                  url={`${window.location.origin}/foams/${foam.id}`}
                /> */}
              </>
            )}
          </DetailsContainer>
        </LeftContainer>
        <VerticalDivider />
        <RightContainer>
          {isEditing ? (
            <>
              <Button size={"small"} width={120} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button
                size={"small"}
                width={120}
                backgroundColor={theme.colors.green}
                onClick={handleSaveClick}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <Button
                size={"small"}
                width={120}
                backgroundColor={theme.colors.blue}
                onClick={handleEditClick}
              >
                Edit
              </Button>
              <Select
                width={120}
                options={privacyOptions}
                selectedOption={selectedOption}
                onOptionChange={handlePrivacyChange}
              />
              <ShareButton url={`${window.location.origin}/foams/${foam.id}`} />
            </>
          )}
        </RightContainer>
      </Container>
    </>
  );
};

export default FoamRow;
