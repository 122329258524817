import { Email } from "react-obfuscate-email";
import { Helmet } from "react-helmet";
import Layout from "components//general/Layout";
import Wrapper from "components/general/Wrapper";
import styled from "styled-components/macro";
import theme from "../theme.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  row-gap: ${theme.spacing.unit * 4}px;
  margin: ${theme.spacing.unit * 12}px 0;
  align-items: center;
`;

const Title = styled.div`
  text-align: center;

  font-size: 40px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0.02em;
`;

const Body = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.02em;
  text-align: center;
  max-width: 600px;
`;

function Support() {
  return (
    <Layout>
      <Helmet>
        <title>Foamy Support</title>
      </Helmet>
      <Wrapper width={920}>
        <Container>
          <Title>Have You Tried Turning Off and On Again?</Title>
          <Body>
            We've done our best to build Foamy to play nice with Netflix, but if
            you run into problems please let us know. Feedback and suggestions
            are also welcome!
            <br />
            <br />
            You can reach us at <Email email="support@foamy.video" />.
          </Body>
        </Container>
      </Wrapper>
    </Layout>
  );
}

export default Support;
