import { useEffect, useState } from "react";

import FoamDate from "components/general/FoamDate";
import { Helmet } from "react-helmet";
import Layout from "components//general/Layout";
import Popcorn from "components/general/Popcorn";
import RichTextReader from "components/general/RichTextReader";
import ShareButton from "components/buttons/ShareButton";
import UserLink from "components/general/UserLink";
import WatchButton from "components/buttons/WatchButton";
import Wrapper from "components/general/Wrapper";
import axios from "axios";
import styled from "styled-components/macro";
import theme from "theme.js";
import { useParams } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  row-gap: ${theme.spacing.unit * 4}px;
  margin: ${theme.spacing.unit * 12}px 0;
`;

const Thumbnail = styled.img`
  width: 100%;
  border-radius: 16px;
  border: 3px solid #000;
  box-sizing: border-box;
  // box-shadow: inset 0 0 0 3px #000;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.unit * 2}px;
  padding-top: ${theme.spacing.unit * 2}px;
`;

const VideoRowContainer = styled.div`
  display: flex;
  column-gap: 4px;
`;

const VideoTitle = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.02em;
`;

const StyledFoamDate = styled(FoamDate)`
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.02em;
`;

const Title = styled(RichTextReader)`
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 40px */
  letter-spacing: 1.28px;
`;

const CreatorAndBubblesContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.unit * 6}px;
`;

const BubbleCountContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.unit * 2}px;
`;

const Description = styled(RichTextReader)`
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.54px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  column-gap: ${theme.spacing.unit * 3}px;
  align-items: center;

  ${theme.media.phone`
    flex-direction: column;
    row-gap: ${theme.spacing.unit * 2}px;
  `}
`;

function Foam() {
  const { id } = useParams();

  const [foam, setFoam] = useState(null);

  useEffect(() => {
    const getFoam = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/api/foams/${id}`
      );
      console.log(response.data);
      setFoam(response.data);
    };
    getFoam();
  }, [id]);

  let videoTitle = foam?.video?.title;
  if (foam?.video?.type === "episode") {
    videoTitle += ` (${foam?.video?.episodeTitle})`;
  }

  return (
    <Layout>
      <Helmet>
        <title>{`${foam?.title} | Foamy`}</title>
      </Helmet>
      <Wrapper width={920}>
        <Container>
          {foam && (
            <>
              <Thumbnail src={foam.video.thumbnailUrl} width="100%" />
              <TitleContainer>
                <VideoRowContainer>
                  <VideoTitle>{videoTitle}</VideoTitle>
                  <StyledFoamDate foam={foam} hasDot={true} />
                </VideoRowContainer>
                <Title value={foam.title || "No title yet"} />
              </TitleContainer>
              <CreatorAndBubblesContainer>
                {foam.user.username && (
                  <div>
                    <UserLink foam={foam} />
                  </div>
                )}
                <BubbleCountContainer>
                  <Popcorn>{foam.bubbles.length}</Popcorn> Bubbles
                </BubbleCountContainer>
              </CreatorAndBubblesContainer>
              <Description value={foam.description || "No description yet"} />
              <ButtonsContainer>
                <WatchButton foam={foam} />
                <ShareButton
                  url={`${window.location.origin}/foams/${foam.id}`}
                />
              </ButtonsContainer>
            </>
          )}
        </Container>
      </Wrapper>
    </Layout>
  );
}

export default Foam;
